<template>
  <v-app id="inspire">
    <v-main>
        <router-view v-if="usuario.logado"></router-view>
    </v-main>

    <v-navigation-drawer
      v-model="drawer"
      app
      color="primary"
    >
      <v-img
        alt="Serpros Fundo Multipatrocinado"
        src="img/serpros-logo-branco.png"
        max-height="120"
        contain
      />


      <v-container fluid >

        <Relogio
        :time-left="$store.state.tempoRestante"
         v-if="$store.state.usuario.logado && $store.state.tempoRestante >= 900"
         dark
        />

        <br>

        <div v-html="$store.state.ajuda"> </div>

      </v-container>

    </v-navigation-drawer>
    
  </v-app>
</template>




<script>
import Relogio from "./components/Relogio";
export default {
  components: {
    Relogio
  },
  name: 'App',
  data: function(){
    return {
      usuario: {
        logado: true
      },
      drawer: null,
    }
  },
  created: function(){

  },  

}
</script>









