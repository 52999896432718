<template>
  <v-container fluid>
      <v-snackbar
        v-model="exibe_alerta"
        timeout="4000"
        :color="alerta_color"
      >
        {{ alerta }}
      </v-snackbar> 

      <v-form ref="form" v-model="form_valido" lazy-validation>  

        <br>
        <v-row v-if="loading" class="mt-5">
          <v-col>
            <div class="text-center" >
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </v-col>
        </v-row>  
        <v-row v-if="loading" >
          <v-col>
            <div class="text-center" >Carregando</div>
          </v-col>
        </v-row> 

        <v-row v-if="!loading && participante.cpf != null  ">
          <v-col>
            <v-card >
                  <v-card-title>
                      <v-row>
                          <v-col class="text-left">
                              <h4 class="text--secondary"> Termo de adesão ao Plano Serpro II - PSII</h4>
                          </v-col>
                      </v-row>
                  </v-card-title>
                  <v-card-text >
                  <div>
                    <v-row class="mb-n5">

                      <v-col cols="12" md="6"> 
                        <v-text-field dense label="Nome do Plano" v-model="plano.nome" filled readonly></v-text-field>
                      </v-col>   
                      <v-col cols="12" md="6"> 
                        <v-text-field dense label="CNPB" v-model="plano.cnpb" filled readonly ></v-text-field>
                      </v-col> 

                    </v-row>  
                    <v-row class="mb-n5">

                      <v-col cols="12" md="6"> 
                        <v-select
                          label="* Nome da Empresa"
                          v-model="participante.id_emp"
                          :items="patrocinadoras"
                          item-text="descricao"
                          item-value="id"
                          dense
                          :rules="[obrigatorio]"
                          
                        > 
                        </v-select>
                  
                      </v-col>   
                      <v-col cols="12" md="6"> 
                        <v-select
                          label="* Regional"
                          v-model="participante.id_regional"
                          :items="regionais"
                          item-text="nm_local"
                          item-value="id_local"
                          dense
                          :rules="[obrigatorio]"
                        > 
                        </v-select>
                      </v-col> 
                    </v-row>  
                    
                  </div>  
                  </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="!loading &&  participante.cpf != null  ">
          <v-col>
            <v-card >
                  <v-card-title>
                      <v-row>
                          <v-col class="text-left">
                              <h4 class="text--secondary">Dados do Proponente</h4>
                          </v-col>
                      </v-row>
                  </v-card-title>
                  <v-card-text >
                  <div>
                    <v-row class="mb-n5">

                      <v-col cols="12" md="6"> 
                        <v-text-field filled dense readonly v-model="participante.nome" label="Nome"   ></v-text-field> 
                      </v-col> 
                      <v-col cols="12" md="3">
                        <v-text-field filled dense readonly v-model="participante.cpf" label="CPF"  :rules="[obrigatorio, ...cpf(participante.cpf), ...maxLength(11)]" ></v-text-field>
                      </v-col>  
                      <v-col cols="12" md="3" v-if="participante.existe_trust"> 
                        <v-text-field  filled dense readonly v-model="participante.matricula" label="Matrícula"  :rules="[obrigatorio, ...maxLength(10)]" ></v-text-field>
                      </v-col> 
                      <v-col cols="12" md="3" class="mt-1" v-if="!participante.existe_trust"> 
                        <v-text-field  v-model="participante.matricula" label="Matrícula"  :rules="[obrigatorio, ...maxLength(10)]" ></v-text-field>
                      </v-col>
                      
                    </v-row>  

                    <v-row class="mb-n5">

                      <v-col cols="12" md="2" v-if="!participante.existe_trust || (participante.existe_trust && !tem_data_admissao)">
                        <!-- <v-text-field  type="date" dense v-model="reqDetalhes.data_admissao" label="* Data de Admissão"  :rules="[...obrigatorio]" ></v-text-field> -->
                        <comp-data v-model="participante.data_admissao" titulo="* Data de Admissão" :outlined="false" :rounded="false"  :obrigatorio="true" :dense="true" :rules="[obrigatorio, ...dataValida(participante.data_admissao)]"/>
                      </v-col>  
                      <v-col cols="12" md="2" v-if="participante.existe_trust && tem_data_admissao">
                        <!-- <v-text-field  type="date" dense v-model="reqDetalhes.data_admissao" label="* Data de Admissão"  :rules="[...obrigatorio]" ></v-text-field> -->
                        <comp-data v-model="participante.data_admissao" titulo="* Data de Admissão" :outlined="false" :rounded="false"  :readonly="true" :obrigatorio="true" :dense="true" :rules="[obrigatorio, ...dataValida(participante.data_admissao)]"/>
                      </v-col>

                      <v-col cols="12" md="3"> 
                        <v-text-field dense v-model="participante.rg" autocomplete= "none" label="* Identidade" :rules="[obrigatorio, ...maxLength(20)]" ></v-text-field>
                      </v-col>  

                      <v-col cols="12" md="3">
                        <v-text-field dense v-model="participante.rg_emissor" autocomplete= "none" label="* Emissor"  :rules="[obrigatorio, ...maxLength(20)]" ></v-text-field>
                      </v-col> 

                      <v-col cols="12" md="2">
                        <v-select
                          label="* UF"
                          v-model="participante.rg_uf"
                          :items="estados"
                          dense
                          :rules="[obrigatorio]"
                        > 
                        </v-select>
                      </v-col> 

                      <v-col cols="12" md="2" >
                        <comp-data v-model="participante.rg_data_emissao" titulo="Emissão" :outlined="false" :rounded="false" :dense="true" :rules="[...dataValida(participante.rg_data_emissao), ...maxLength(10)]"/>
                      </v-col> 
                      
                    </v-row>  

                    <v-row class="mb-n5">

                      <v-col cols="12" md="6">
                        <v-select
                          label="* Nacionalidade"
                          v-model="participante.id_nacionalidade"
                          :items="nacionalidade"
                          item-text="descricao"
                          item-value="id"
                          :rules="[obrigatorio]"
                          dense
                          
                        > 
                        </v-select>
                      </v-col>  

                      <v-col cols="12" md="3"> 
                        <v-text-field dense v-model="participante.naturalidade" autocomplete="none" label="* Naturalidade" :rules="[obrigatorio, ...maxLength(32)]" ></v-text-field>
                      </v-col>  

                      <v-col cols="12" md="3">
                        <v-select
                          label="* UF Natural"
                          v-model="participante.uf_natural"
                          :items="estados"
                          dense
                          :rules="[obrigatorio]"
                        > 
                        </v-select>
                      </v-col> 
                      
                    </v-row>  



                    <v-row class="mb-n5">

                      <v-col cols="12" md="4">
                        <v-select
                          label="* Estado civil"
                          v-model="participante.id_estado_civil"
                          :items="estado_civil"
                          item-text="descricao"
                          item-value="id"
                          :rules="[obrigatorio]"
                          dense
                          
                        > 
                        </v-select>
                      </v-col>   

                      <v-col cols="12" md="4"> 
                        <v-select
                          label="Sexo"
                          v-model="participante.sexo"
                          :items="sexo"
                          item-text="descricao"
                          item-value="id"
                          dense
                          :rules="[obrigatorio]"
                        > 
                        </v-select>
                      </v-col> 

                      <v-col cols="12" md="4" v-if="!participante.existe_trust">
                        <comp-data  v-model="participante.data_nascimento" titulo="* Nascimento" :outlined="false" :rounded="false"  :dense="true" :rules="[obrigatorio, ...dataValida(participante.data_nascimento), ...maxLength(10)]"/>
                        
                      </v-col> 
                      <v-col cols="12" md="4" v-if="participante.existe_trust"> 
                        <comp-data v-model="participante.data_nascimento" titulo="* Nascimento" :outlined="false" :rounded="false"  :dense="true" :readonly="true" :rules="[obrigatorio, ...dataValida(participante.data_nascimento), ...maxLength(10)]"/>
                        
                      </v-col> 
 

                    </v-row>

                    <v-row class="mb-n5">

                      <v-col cols="12" md="6">
                        <v-text-field  dense v-model="participante.nome_pai" autocomplete="none" label="Nome do pai" :rules="[...maxLength(64)]" ></v-text-field> 
                      </v-col>  

                      <v-col cols="12" md="6"> 
                        <v-text-field dense v-model="participante.nome_mae" autocomplete="none" label="* Nome da mãe" :rules="[obrigatorio, ...maxLength(64)]" ></v-text-field>
                      </v-col> 

                    </v-row>


                    <v-row class="mb-n5">

                      <v-col cols="12" md="2">
                        <v-text-field 
                            autocomplete="none"
                            :disabled="participante.id_pais != 31"
                            dense 
                            v-model="participante.cep" 
                            label="* CEP"  
                            :rules="[obrigatorio, ...cep(participante.cep), ...maxLength(8)]"
                            append-outer-icon="mdi-text-search" 
                            @click:append-outer="consultaCEP(participante.cep, participante)"></v-text-field>
                      </v-col> 

                      <v-col cols="12" md="4"> 
                        <v-text-field dense v-model="participante.endereco" autocomplete="none" label="* Endereço" :rules="[obrigatorio, ...maxLength(60)]" ></v-text-field>
                      </v-col> 

                      <v-col cols="12" md="3"> 
                        <v-text-field dense v-model="participante.numero" autocomplete="none" label="* Número" :rules="[obrigatorio, ...maxLength(8)]" ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="3"> 
                        <v-text-field dense v-model="participante.complemento" autocomplete="none" label="Complemento" :rules="[...maxLength(36)]" ></v-text-field>
                      </v-col> 

                    </v-row>

                    <v-row class="mb-n5">

                      <v-col cols="12" md="5">
                        <v-text-field  dense v-model="participante.bairro" autocomplete="none" label="* Bairro" :rules="[obrigatorio, ...maxLength(20)]" ></v-text-field>
                      </v-col>  



                      <v-col cols="12" md="5"> 
                        <v-text-field dense v-model="participante.cidade" autocomplete="none" label="* Cidade" :rules="[obrigatorio, ...maxLength(30)]" ></v-text-field>
                      </v-col> 

                      <v-col cols="12" md="2">
                        <v-select
                          :disabled="participante.id_pais != 31"
                          label="* Estado"
                          v-model="participante.estado"
                          :items="estados"
                          :rules="[obrigatorio]"
                          dense
                          
                        > 
                        </v-select>
                      </v-col> 
  
                      
                    </v-row>  

                      <v-row class="mb-n5">

                        <v-col cols="12" md="4">
                          <v-text-field autocomplete="none" dense v-model="participante.email_pessoal" label="* Email Pessoal" :rules="[obrigatorio, ...maxLength(60), ...email(participante.email_pessoal)]" ></v-text-field>
                        </v-col> 

                        <v-col cols="12" md="4">
                          <v-text-field dense autocomplete="none" v-model="participante.celular" label="* Celular" :rules="[obrigatorio, ...maxLength(15), ...telefone(participante.celular)]" ></v-text-field>
                        </v-col> 

                        <v-col cols="12" md="4">
                          <v-text-field dense autocomplete="none" v-model="participante.telefone_alternativo" label="Telefone Alternativo" :rules="[...maxLength(15), ...telefone(participante.telefone)]" ></v-text-field>
                        </v-col>
                         
                    </v-row>
                  </div>  
                  </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        

        <v-row v-if="!loading &&  participante.cpf != null" > 
          <v-col>
              <v-card>
                  <v-card-title>
                      <v-row>
                          <v-col class="text-left">
                              <h4 class="text--secondary">Tempo de Serviço</h4>
                          </v-col>
                      </v-row>
                  </v-card-title>
                <v-card-actions class="mb-n5">
                  <v-spacer></v-spacer>
                  <v-btn rounded color="#B0BEC5" @click="AddEmpresa"> <v-icon>mdi-plus-circle-outline</v-icon> Adicionar </v-btn>
                </v-card-actions> 
                <v-card-text>
                  <div>
                    <v-container
                      v-for="empresa in empresas"
                      :key="empresa.id"
                    >
                      <v-row class="mb-n5">
                        <v-col cols="12" md="4">
                          <v-text-field  dense v-model="empresa.nome_empresa" autocomplete="none" label="* Nome da Empresa" :rules="[obrigatorio, ...maxLength(60)]" ></v-text-field>
                        </v-col>  
                        <v-col cols="12" md="2"> 
                          <v-select
                            label="* Categorias"
                            v-model="empresa.id_tipo_tempo_servico"
                            :items="tipoTempoServico"
                            item-text="descricao"
                            item-value="id"
                            :rules="[obrigatorio]"
                            dense
                            
                          > 
                          </v-select>
                        </v-col>  
                        <v-col cols="12" md="2">
                          <!-- <v-text-field  type="date" dense autocomplete= "none" v-model="empresa.data_admissao" label="* Data de admissão"  :rules="[...obrigatorio, ...DataMaiorQAtual(empresa.data_admissao)]" @change="IdadeAdmissao(empresa.data_admissao)" ></v-text-field> -->
                          <comp-data v-model="empresa.data_admissao" titulo="* Data de admissão" :outlined="false" :rounded="false"  :obrigatorio="true" :dense="true" @change="IdadeAdmissao(empresa.data_admissao)"  :rules="[obrigatorio, ...DataMenor(empresa.data_admissao, empresa.data_demissao), ...dataValida(empresa.data_admissao)]"/>
                        </v-col> 
                        <v-col cols="12" md="2">
                          <!-- <v-text-field  type="date" dense autocomplete= "none" v-model="empresa.data_demissao" label="* Data de demissão"  :rules="DataMenor(empresa.data_admissao, empresa.data_demissao)" ></v-text-field> -->
                          <comp-data v-model="empresa.data_demissao" titulo="* Data de demissão" :outlined="false" :rounded="false"  :obrigatorio="false" :dense="true"  :rules="[...DataMenor(empresa.data_admissao, empresa.data_demissao), ...dataValida(empresa.data_demissao)]"/>
                        </v-col>  
                        <v-col cols="12" md="2">
                          <v-btn rounded color="#B0BEC5" @click="DelEmpresa(empresa.id)" > <v-icon>mdi-minus-circle-outline</v-icon> Excluir </v-btn>
                        </v-col> 
                      </v-row>
                    </v-container> 
                    <br>
                    <v-row class="mb-n5"> 
                      <v-col cols="12" md="12" class="text-h6  text-justify ">
                        O tempo total para o INSS será efetivamente alterado na base de dados no dia seguinte às modificações.
                        Ao confirmar, o participante declara serem verdadeiras as informações ora prestadas, assumindo o ônus por eventual imprecisão
                      </v-col>
                    </v-row>
                    
                  </div>  
                </v-card-text>
              </v-card>
          </v-col>
        </v-row>

        <v-row v-if="!loading &&  participante.cpf != null" > 
          <v-col>
              <v-card>
                <v-card-title>
                    <v-row>
                        <v-col class="text-left">
                            <h4 class="text--secondary">Politicamente exposto</h4>
                        </v-col>
                    </v-row>
                </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-select

                        v-model="participante.politicamente_exposto"
                        :items="sim_nao"
                        item-text="descricao"
                        item-value="id"
                        dense
                        @input="validaPep" 
                        :rules="regraPep"

                      > 
                      </v-select>
                    </v-col> 
                  </v-row>
                        
                </v-card-text>

                <v-card-actions class="mb-n5" v-if="participante.politicamente_exposto =='S'">
                  <v-spacer></v-spacer>
                  <v-btn rounded color="#B0BEC5" @click="AddPep"> <v-icon>mdi-plus-circle-outline</v-icon> Adicionar </v-btn>
                </v-card-actions> 

                <v-card-text v-if="participante.politicamente_exposto =='S'">
                  <div>
                    <v-container
                      v-for="pep in peps"
                      :key="pep.id"
                    > 
                       <v-card color="#ECEFF1">
                        <v-card-text>
                          <v-row class="mb-n5">
                            <v-col cols="12" md="3">
                              <v-text-field  v-model="pep.cpf" dense autocomplete="none"  label="* CPF" :rules="[obrigatorio, ...cpf(pep.cpf), ...maxLength(11)]"  ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                              <comp-data v-model="pep.data_inicio" titulo="* Data Início" :outlined="false" :rounded="false"   :dense="true" :rules="[obrigatorio, ...DataMenor(pep.data_inicio, pep.data_fim), ...dataValida(pep.data_inicio)]" />
                            </v-col> 
                            <v-col cols="12" md="3">
                              <comp-data v-model="pep.data_fim" titulo="Data Fim" :outlined="false" :rounded="false"   :dense="true"  :rules="[...DataMenor(pep.data_inicio, pep.data_fim), ...dataValida(pep.data_fim)]" />
                            </v-col>  
                            
                          </v-row>
                          <v-row class="mb-n5">
                            
                            <v-col cols="12" md="3">
                              <!-- <v-text-field  v-model="pep.vinculo" dense autocomplete= "none"  label="* Vínculo" :rules="[obrigatorio, ...maxLength(16)]"  ></v-text-field> -->
                              <v-select
                                label="* Parentesco"
                                v-model="pep.id_parentesco"
                                :items="parentesco"
                                item-text="descricao"
                                item-value="id"
                                dense
                                :rules="[obrigatorio]"
                              > 
                              </v-select>
                            </v-col> 
                            <v-col cols="12" md="3">
                              <v-text-field  v-model="pep.pessoa" dense autocomplete= "none"  label="* Pessoa vinculada"  :rules="[obrigatorio, ...maxLength(64)]" ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                              <v-text-field  v-model="pep.ocupacao" dense autocomplete= "none"  label="* Ocupação"  :rules="[obrigatorio, ...maxLength(32)]"  ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3" class="text-right">
                              <v-btn rounded color="#B0BEC5" @click="DelPep(pep.id)" > <v-icon>mdi-minus-circle-outline</v-icon> Excluir </v-btn>
                            </v-col> 
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-container> 
                    
                  </div>  
                </v-card-text>
              </v-card>
          </v-col>
        </v-row>


        <v-row v-if="!loading && participante.cpf != null" > 
          <v-col>
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" >
                      <P class="text-h6">Por meio do presente Termo de Adesão ao Plano Serpro PS-II, declaro, expressamente: </P>
                      <P class="text-h6">1. Minha adesão, na qualidade de Participante, ao Plano de Benefícios Serpro II (“PS-II”), inscrito no Cadastro Nacional de Planos de Benefícios do Ministério da Previdência Social sob o nº 19.980.077-74;</P>
                      <P class="text-h6">2. Que tenho ciência e concordo com:</P>
                      <P class="text-h6" style="margin-left: 50px;">a. A efetivação da adesão mencionada no item 1, e que esta ocorrerá no dia 1ª do mês subsequente ao recebimento deste na instituição;</P>
                      <P class="text-h6" style="margin-left: 50px;">b. Além da Contribuição Variável e da Contribuição Espontânea Mensal, para as quais estou me manifestando nos itens 4 e 5, abaixo, efetuarei outras contribuições previstas no Capítulo IV do Regulamento do PS-II, cujos valores são apurados atuarialmente;</P>
                      <P class="text-h6" style="margin-left: 50px;">c. Qualquer alteração que venha a ocorrer na legislação que rege as Entidades Fechadas de Previdência Complementar ou no Regulamento do PS-II é aplicável a todos os Participantes e Assistidos do PS-II, na forma da lei;</P>
                      <P class="text-h6">3. Em relação à minha contribuição variável, minha OPÇÃO pelo percentual de: </P>
                      <v-row>
                        <v-col cols="12" md="2">
                            <v-text-field style="margin-left: 50px;"  type="number" outlined hide-spin-buttons dense v-model="participante.percentual_variavel" autocomplete= "none" suffix="%" :rules="[obrigatorio, ...maxVariavel(participante.percentual_variavel), ...espontanea()]" ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="10">
                            <P class="text-body"> * O limite é de 15% (quinze por cento) aplicado sobre a parcela do Salário de Contribuição, excedente a 8 VRS.</P>
                        </v-col>
                      </v-row>
                      
                        
                      <p class="text-h6">4. Em relação à Contribuição Espontãnea Mensal:</p>
                      <v-row>
                        <v-col cols="12" md="9">
                            <v-select
                              outlined
                              v-model="participante.espontanea_sim_nao"
                              :items="opcoes_espontanea"
                              item-text="descricao"
                              item-value="id"
                              style="margin-left: 50px;"
                              dense
                              :rules="[obrigatorio, ...espontanea()]"
                              @change="participante.valor_espontanea = 0"
                            > 
                            </v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="2" v-if="participante.espontanea_sim_nao == 'S'">
                            <v-text-field reverse style="margin-left: 50px;"  type="number" outlined hide-spin-buttons dense v-model="participante.valor_espontanea" autocomplete= "none" prefix=",00" suffix="R$" :rules="[obrigatorio, ...espontanea()]" ></v-text-field>
                        </v-col>
                        
                      </v-row>
                      
                      <p class="text-h6">5. a relação, a seguir, das pessoas inscritas no PSII como meus Beneficiários e designados</p>
                    </v-col> 
                  </v-row>
                  
                        
                </v-card-text>
              </v-card>
              <br>
              <v-card>  

                <v-card-title>
                    <v-row>
                        <v-col class="text-left">
                            <h4 class="text--secondary">Beneficiários e designados</h4>
                        </v-col>
                    </v-row>
                </v-card-title>

                <v-card-actions class="mb-n5">
                  <v-spacer></v-spacer>
                  <v-btn class="mb-2" rounded color="#B0BEC5" @click="AddDependente" > <v-icon>mdi-plus-circle-outline</v-icon>Adicionar</v-btn>
                </v-card-actions> 

                <v-card-text>                 

                  <v-container
                      v-for="dependente in dependentes"
                      :key="dependente.id_dependente"
                      fluid
                    >
                      <v-card color="#ECEFF1">
                        <v-card-text>
                          <v-row class="mb-n5">
                            <v-col cols="12" md="3">
                              <v-text-field  autocomplete= "none" dense v-model="dependente.nome" label="* Nome" :rules="[obrigatorio, ...maxLength(64)]" ></v-text-field>
                            </v-col> 
                            <v-col cols="12" md="2">
                              <v-text-field  autocomplete= "none" dense v-model="dependente.cpf" label="* CPF" :rules="[obrigatorio, ...cpf(dependente.cpf), ...maxLength(11)]" ></v-text-field>
                            </v-col> 
                            <v-col cols="12" md="2">
                              <v-select
                                label="* Parentesco"
                                v-model="dependente.id_parentesco"
                                :items="parentesco"
                                item-text="descricao"
                                item-value="id"
                                dense
                                :rules="[obrigatorio]"
                                @input="Bigamia()" 
                              > 
                              </v-select>
                            </v-col>

                            <v-col cols="12" md="2">
                              <v-select
                                label="* Sexo"
                                v-model="dependente.sexo"
                                :items="sexo"
                                item-text="descricao"
                                item-value="id"
                                dense
                                :rules="[obrigatorio]"
                              > 
                              </v-select>
                            </v-col> 

                            <v-col cols="12" md="2">
        
                              <!-- <v-text-field  type="date" autocomplete= "none" dense v-model="dependente.data_nascimento" label="* Data de nascimento"   @change="IdadeParente(dependente.id_parentesco, participante.data_nascimento, dependente.pessoa_fisica.data_nascimento);" ></v-text-field> -->
                              <comp-data v-model="dependente.data_nascimento" titulo="* Nascimento" :outlined="false" :rounded="false"  :obrigatorio="true" :dense="true" :rules="[obrigatorio, ...dataValida(dependente.data_nascimento), ...maxLength(10)]"/>
                            </v-col>
                            
                          </v-row>

                          <v-row class="mt-2">

                            <v-col cols="12" md="2">
                              <v-select
                                label="* Beneficiário"
                                v-model="dependente.beneficiario"
                                :items="sim_nao"
                                item-text="descricao"
                                item-value="id"
                                :rules="[obrigatorio]"
                                dense
                                
                              > 
                              </v-select>
                            </v-col> 
                            <v-col cols="12" md="2">
                              <v-select
                                label="* Designado"
                                v-model="dependente.designado"
                                :items="sim_nao"
                                item-text="descricao"
                                item-value="id"
                                :rules="[obrigatorio]"
                                dense
                                @input="designado(dependente)"
                                
                                
                              > 
                              </v-select>
                            </v-col>
                            <v-col cols="12" md="2">
                              <v-text-field  dense v-model="dependente.percentual" :readonly="dependente.designado == 'N'"  label="Perc. pecúlio"  suffix="%" :rules="[...Rateio(dependente.percentual, dependente.designado)]"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="text-right">
                              <!--<v-btn rounded color="#B0BEC5"  @click=DelPlano(plano.id) > <v-icon>mdi-minus-circle-outline</v-icon></v-btn> -->
                              <v-btn rounded color="#B0BEC5" @click="DelDependente(dependente.id_dependente)" > <v-icon>mdi-minus-circle-outline</v-icon>Excluir</v-btn>
                            </v-col>
                          </v-row> 

                          
    

                        </v-card-text>
                      </v-card>  
                    </v-container>
                </v-card-text>

                
                <v-card-text>
                  <v-row class="mb-n5"> 
                    <v-col cols="12" md="12" class="text-h6  text-justify">
                      <P style="margin-left: 50px;">Obs: - O reconhecimento de Beneficiário pelo PS-II depende do atendimento às condições de elegibilidade previstas no Regulamento do PS-II. </P>
                      <P style="margin-left: 50px;">- Os Beneficiários e Designados podem ser substituídos a qualquer tempo, pelo Participante.</P>
                    </v-col>  
                  </v-row>
                </v-card-text>  
              </v-card>
              <br>
            <!--  <v-card>
                <v-card-text class="text-h6">
                      <P>Em relação ao regime de tributação:</P>
                        <v-radio-group v-model="participante.regime_tributacao" :rules="[obrigatorio]">
                          <v-radio
                            label="a. minha opção pelo regime previsto no artigo 1º da lei nº 11.053, de 29 de dezembro de 2004 (escolha pela tributação Regressiva)"
                            value="A"
                            key="A"
                          ></v-radio>
                          <v-radio
                            label="b. minha opção pelo regime de Tributação Progressiva conforme determinação do Governo Federal;"
                            value="B"
                            key="B"
                          ></v-radio>
                          <v-radio
                            label='c. que me manifestarei até o último dia útil do mês subsequente ao meu ingresso no PS-II, 
                                   sendo que a ausência de manifestação presumirá minha NÃO OPÇÃO pelo regime previsto no artigo 1º da Lei nº 11.053, 
                                   de 29 de dezembro de 2004 (escolha pela Tributação Progressiva).'
                                   
                            value="C"
                            key="C"
                          ></v-radio> 
                        </v-radio-group>


                      <P>Se você escolheu a opção C baixe o formulário de opção <a target="_blank" href="https://serpros.com.br/formulario-opcao-ir/">aqui</a> e envie para o Serpros.</P>
                      <P>Autorizo o Serpros a obter meus dados pessoais e funcionais com o Patrocinador, a fim de inserí-los e mantê-los atualizados no cadastro do PS-II;</P>
                      <P>Autorizo o Patrocinador a descontar do meu salário os valores devidos ao PS-II e transferi-los a este plano de benefícios;</P>
                      <P>Estou ciente de que qualquer informação inverídica aqui prestada caracteriza crime de falsidade ideológica, nos termos da lei, 
                        sujeitando-me a arcar com o ônus que a mesma venha a causar ao PS-II ou ao Serpros.
                      </P>
                </v-card-text>  
              </v-card> -->
          </v-col>
        </v-row>

        <v-row v-if="!loading && requerimento.id != null && !exibe_escolha" > 
          <v-col>
            <v-card outlined elevation="4" v-if="!loading">
              <v-card-title>
                  <v-row>
                      <v-col class="text-left">
                          <h4 class="text--secondary"> Documentos necessários do titular</h4>
                      </v-col>
                  </v-row>
              </v-card-title>
              
              <v-card-text>
                <v-data-table
                  :headers="header"
                  :items="FiltraDocsObrigatorios(requerimento.id_tipo_requerimento)"
                  sort-by="data_requerimento"
                  sort-desc
                  class="elevation-2 table"
                  no-data-text="Não há nenhum arquivo"
                  :items-per-page="-1"
                  :loading="loading.anexo"   
                  loading-text="Carregando arquivos..."
                  hide-default-footer
                  item-key="id_req_doc"
                  >


                  <template v-slot:[`item.clipe`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small class="mr-2"  @click="EscolheOuEnvia(item.id_tipo_documento, item.id_req_doc)" >
                          <v-icon 
                          v-bind="attrs"
                          v-on="on"
                          >mdi-paperclip</v-icon>
                        </v-btn>
                      </template>
                      <span>Anexar</span>
                    </v-tooltip>
                    <v-icon color="orange"  elevation="4" v-if="item.situacao === 'E'">mdi-alert-circle-outline</v-icon>
                    <v-icon color="green"   elevation="4" v-else-if="item.situacao === 'D'">mdi-check-circle-outline</v-icon>
                    <v-icon color="primary" elevation="4" v-else></v-icon>
                  </template>
                </v-data-table> 
              </v-card-text>  
            </v-card>
          </v-col>
        </v-row>



        <v-row v-if="!loading && requerimento.id != null && exibe_escolha" > 
          <v-col>
            <v-card outlined elevation="4" >
              <v-card-title>
                  <v-row>
                      <v-col class="text-left">
                          <h4 class=" text--secondary"> Carregar arquivo</h4>
                      </v-col>
                  </v-row>
              </v-card-title>
              
              <v-card-text>
                <v-row>
                  <v-col class="text-left">
                    <v-radio-group v-model="escolhido" >
                      <v-radio
                        v-for="opcao in opcoes"
                        :key="opcao.id"
                        :label="opcao.descricao"
                        :value="opcao.id"
                        class="pa-2"
                        :off-icon="opcao.id == escolhido ? '$radioOn' : '$radioOff'"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
              </v-row>

              <v-row>
                  <v-col class="text-left">
                      <v-btn class="mr-3" rounded  color="#B0BEC5"   @click="CarregaArquivo()" v-if="!loading && !dialog"> Prosseguir </v-btn>
                      <v-btn  rounded  color="#B0BEC5"   @click="cancelarEscolha()" v-if="!loading && !dialog"> Cancelar </v-btn>
                  </v-col>
                  <v-col class="text-left">
                    <v-file-input
                      truncate-length="150"
                      accept='.pdf,.png,.jpg,.jpeg'
                      v-model="arquivo"
                      id="fileUpload1"
                      :rules="Rules.Arquivo"
                      hide-input
                      prepend-icon=""
                      @change="enviarArquivo()"
                    ></v-file-input>
                  </v-col>
              </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        

        
        <v-row v-if="!loading && participante.cpf != null">
          <v-col>
            <v-btn rounded class="mr-3 mb-2" color="#B0BEC5"  @click="submitForm('L')"> <v-icon>mdi-content-save</v-icon> Salvar </v-btn>
            <v-btn rounded class="mr-3 mb-2" color="green"   @click="submitForm('A')"  :disabled="!pode_enviar" v-if="!loading && !exibe_escolha"> Finalizar requerimento </v-btn>
            <v-btn rounded class="mb-2"      color="#B0BEC5"  @click="btnsair"> <v-icon>mdi-arrow-left-circle</v-icon> Sair </v-btn>
          </v-col>
        </v-row>

        <v-overlay
          :absolute="absolute"
          :value="overlay"
        >
          <v-progress-circular indeterminate size="64" ></v-progress-circular>
        </v-overlay>

        <v-overlay
          absolute
          :value="dialog"
        >
        </v-overlay>

        <v-snackbar
          v-model="dialog"
          multi-line
          centered
          :color="cor_dialogo"
          timeout="-1"
          max-width="400"
          min-height="140"
          vertical
        >
          

          <template v-slot:default>
            <div v-html="mensagem"> </div>
          </template>

          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="BtnOk(titulo)"
              v-if="!simnao"
            >
              Ok
            </v-btn>
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="BtnSim(titulo)"
              v-if="simnao"
            >
              Sim
            </v-btn>
                    <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="BtnNao(titulo)"
              v-if="simnao"
            >
              Não
            </v-btn>
          </template>
        </v-snackbar>

      </v-form>  
  </v-container>
</template>

<script>
   import CompData from '@/components/CompData.vue';
   export default {
      components: {
        CompData,
      },
      data: function(){
         return {
            Rules:{
              Arquivo: [v => !!v || 'Escolha um arquivo para enviar',],
            },
            value: "12.34",        // 12.34 or "12.34" or "" or null
            label: "Percent",
            focus: false,
            obrigatorio: v => !!v || 'Obrigatório',
            multiLine: true,
            overlay:false,
            absolute: false,
            form_valido:true,
            loading: true,
            mensagem: '',
            menu_aniv: false,
            dialog: false,
            simnao: false,
            cor_dialogo: '#B00020',
            titulo: null,
            idade_titular: null,
            confirmou_idade: true,
            protocolo: null,
            ultima_atualizacao:null,
            opcoes_espontanea: [
              {id:"N", descricao: "Minha NÃO OPÇÃO pela Contribuição Espontânea Mensal"},
              {id:"S", descricao: "Minha OPÇÃO pela Contribuição Espontânea Mensal no valor em Reais informado abaixo, limitado a 15% do salário de contribuição"}
            ],
            plano: {
                id:   2, 
                nome: "Plano de Benefícios Serpro II", 
                sigla:"PSII",
                cnpb: "1998007774"
            },
            requerimento:{
              id: null,
              protocolo: null,
              id_plano: 2,
              id_tipo: 11,
              id_nuvem: this.$store.state.nuvem.id,
              data_requerimento: null,
              situacao: 'L'
            },
            reqDetalhes:{
              nome_empresa: '',
              regional: '',
              matricula: '',
              data_admissao: '',
              perc_variavel_inicial: 0,
              espontanea_sim_nao: '',
              valor_espontanea_inicial: 0,
              regime_tributacao: null,
            },
            participante:{
              id: null,
              cpf: this.$store.state.cpf,
              nome: this.$store.state.nome,
              data_nascimento: "",
              sexo: null,
              id_nacionalidade: null,
              naturalidade: null,
              id_estado_civil: null,
              id_escolaridade: null,
              pep: null,
              aposentado_inss: null,
              responsavel_fatca: null,
              pai:null,
              mae: null, 
              estado: null,
              cep: '',
              celular: null,
              cidade: null,
              telefone: null,
              bairro: null,
              email_pessoal: null,
              email_extra: null,
              endereco: null,
              numero: null,
              complemento: null,
              id_pais: null,
              rg_data_emissao: "",
              data_admissao: "",
              percentual_variavel: 15,
            },
            participante_planos: [{id_plano:2}],
            empresas: [],
            dependentes: [],
            dependentes_planos:[],
            planos:[{id: 2, sigla: "PSII"}],
            planos_aux:[],
            nacionalidade:[],
            estado_civil:[],
            parentesco:[],
            paises:[],
            plano1:"PSII",
            escolaridade:[],
            tipoTempoServico:[],
            sim_nao:[
              {id:"S", descricao: "SIM"},
              {id:"N", descricao: "NÃO"},
            ],
            estados:["AC", "AL",	"AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"],
            sexo:[
              {id:"M", descricao:"MASCULINO"},
              {id:"F", descricao:"FEMININO"},
            ],
            teste: 0,
            declaracao: false,
            aposentado_pensionista: null,
            exibe_peps: false,
            peps: [],
            regraPep: [true],
            patrocinadoras:[
              {id:2, descricao: "SERVIÇO FEDERAL DE PROCESSAMENTO DE DADOS"},
              {id:1, descricao: "SERPROS FUNDO MULTIPATROCINADO"},
            ],
            regionais: [],
            exibe_alerta: false,
            alerta_color: '#B00020',
            alerta: "",
            header: [
              
                {
                    text: 'Documento',
                    align: 'start',
                    sortable: false,
                    value: 'desc_tipo_documento',
                    width: 220,
                    class: 'title white--text'
                },
                {
                    text: 'Arquivo',
                    value: 'arquivo',
                    sortable: false,
                    width: 180,
                    class: 'title white--text'
                },
                {
                    text: 'Situação',
                    value: 'desc_situacao', 
                    sortable: false,
                    width: 80,
                    class: 'title white--text'
                },
                {
                    text: '',
                    align: 'end',
                    value: 'clipe',
                    sortable: false,
                    width: 40,
                    class: 'title white--text'
                },
            ],
            documentosNecessarios: [],
            exibe_escolha: false,
            opcoes: [
              {id: 1, descricao: "Escolher um documento no meu computador. Tamanho máximo "+this.$store.state.max_file_size+" MB"},
              {id: 2, descricao: "Escolher um documento que está na nuvem do participante"}
            ],
            escolhido: 1,
            arquivo: null,
            pode_enviar: false,
            id_protocolo_ged: null,
            tem_data_admissao: false
         }
      },


      created: function() {

        var ajuda = '<p style="color:white; font-size: 12px;"><b>Beneficiários</b> são as pessoas que terão direito a receber o benefício de Pensão por Morte. Para ser seu beneficiário é necessário ter vínculo legal, tais como: Cônjuge, companheira(o), filhos menores de 21 anos ou 24 se universitários, enteados ou tutelados. </p><br>'+
                    '<p style="color:white; font-size: 12px;"><b>Designados</b> são as pessoas que terão direito a receber Pecúlio por Morte e pode ser qualquer pessoa física, independente de vínculo ou dependência do Participante.</p><br>'+
                    '<P style="color:white; font-size: 12px;">Segundo a lei, uma <b>Pessoa Politicamente Exposta</b> é um indivíduo que tenha exercido cargos, funções ou empregos públicos, políticos, militares ou magistrados nos últimos cinco anos. Também se enquadram nessa definição familiares, representantes e pessoas de convívio próximo aos citados acima.</p><br>'+
                    '<p style="color:white; font-size: 12px;"><b>Tempo de serviço especial</b> é aquele que o trabalho é exercido exposto a agentes nocivos, que podem causar algum prejuízo a saúde e integridade física ao longo do tempo.</p>';

        this.$store.dispatch('exibeajuda',ajuda);

        if(this.$store.state.alerta != ''){
            this.alerta = this.$store.state.alerta;
            this.$store.commit('setalerta','');
            this.alerta_color = "green";
            this.exibe_alerta = true;
            
        }

        this.id_protocolo_ged = this.$store.state.nuvem.id_protocolo_ged;

        this.recuperaTabelas();

        if(typeof this.$route.params.id != 'undefined'){
          this.CarregaRequerimento(this.$route.params.id);
        }else{
          this.CarregaPessoa(this.cpf);
        }
        
      },


      methods: {

        empresaReadOnly: function(){

          if(this.requerimento.situacao == 'E'){
            return false;
          }else{
            if(this.participante.id_emp == 0){
              return false;
            }else{
              return true;
            }
            
            
          }

          
        },

        //#####################
        //Regras de validação do formulário
        //####################
        Rateio: function(numero, designado) {
          let numero_aux ;
          if(typeof numero_aux == "string"){
            numero_aux = numero.replace(",",".");
          }else{
            numero_aux = numero;
          }
          
          if(!isNaN(numero_aux)){
            let perc_aux = 0;
            let soma = 0;
            
            for (let x = 0; x < this.dependentes.length; x++) {
              
                if(typeof this.dependentes[x].percentual == "string"){
                  perc_aux = this.dependentes[x].percentual.replace(",",".");
                }else{
                  perc_aux = this.dependentes[x].percentual;
                }
                soma = soma + parseFloat(perc_aux);

            } 

            let aprovado = false;
            if(soma ==0){
               return  true ;
            }

            if(soma ==100){
              if(!isNaN(numero_aux)){
                if(designado == 'S' && parseFloat(numero_aux) == 0){
                  return  false || 'Percentual deve ser maior que zero'  //false mostra a mensagem
                }
                if(designado == 'N' && parseFloat(numero_aux) != 0){
                  return  false || 'Percentual deve ser zero'  //false mostra a mensagem
                }

              }
              return  true ;

            }else{
              return  false || 'Somatório deve ser 100%'  //false mostra a mensagem
            }

            
          }else{
            return false || 'Somente números'
          }  
        },

        designado: function(dependente){
   
          if(dependente.designado == 'N'){
            dependente.percentual = 0;
          }

        },

        


        maxLength: function(max) {
          return [v => (v || '' ).length <= max || 'Máximo '+max+' caracteres']
        },


        telefone: function(telefone) {
          
          let aprovado = true;
          let mensagem = '';

          if(telefone != null){

            if(telefone.length < 10){
              aprovado = false;
              mensagem = 'Mínimo: ddd + 8 números';
            }

            if(telefone.indexOf('0000000') != -1 || 
              telefone.indexOf('1111111') != -1 || 
              telefone.indexOf('2222222') != -1 || 
              telefone.indexOf('3333333') != -1 || 
              telefone.indexOf('4444444') != -1 || 
              telefone.indexOf('5555555') != -1 || 
              telefone.indexOf('6666666') != -1 || 
              telefone.indexOf('7777777') != -1 || 
              telefone.indexOf('8888888') != -1 || 
              telefone.indexOf('9999999') != -1 || 
              telefone.indexOf('1234567') != -1
              ){

              aprovado = false;
              mensagem = 'Número inválido';
            }
          }  

          return  aprovado || mensagem
        },

        
        cep: function(cep) {
          
          let aprovado = true;
          let mensagem = '';

          if(this.participante.id_pais == 31){

            if(cep != null){
              if(cep.length < 8){
                aprovado = false;
                mensagem = 'Mínimo 8 números';
              }

              if(cep.length < 1){
                aprovado = false;
                mensagem = 'Obrigatório';
              }

              if(cep.indexOf('0000000') != -1 || 
                cep.indexOf('1111111') != -1 || 
                cep.indexOf('2222222') != -1 || 
                cep.indexOf('3333333') != -1 || 
                cep.indexOf('4444444') != -1 || 
                cep.indexOf('5555555') != -1 || 
                cep.indexOf('6666666') != -1 || 
                cep.indexOf('7777777') != -1 || 
                cep.indexOf('8888888') != -1 || 
                cep.indexOf('9999999') != -1 || 
                cep.indexOf('1234567') != -1
                ){

                aprovado = false;
                mensagem = 'CEP inválido';
              }
            }else{
              return true
            }

            
          }

          return  aprovado || mensagem
        },


        email: function(email) {

          var resultado = true;
          var mensagem = '';

          if (email != null && email != '' ){
            var upper_email = email.toUpperCase();
            
            if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(upper_email))
            {
              resultado = false;
              mensagem = 'E-mail inválido';
            }



            if( upper_email.indexOf("SERPROS.COM") != -1 || upper_email.indexOf(".GOV.BR") != -1 ){
              resultado = false;
              mensagem = 'use seu e-mail particular';
            }
            
          }

          
          
          return  resultado || mensagem
        },


        aposInss: function(valor, sexo) {

          var resultado = true;
          var mensagem = '';
          var idademinima = 0;

          if (valor != null){
            if(valor == 'S'){

              if(sexo == 'F'){
                idademinima = 44;
              }else{
                idademinima = 49;
              }

              if(this.idade(this.participante.data_nascimento) < idademinima){
                resultado = false;
                mensagem = 'Idade inferior a '+idademinima+' anos';
              }
            }            
          }
          
          return  resultado || mensagem
        },


        DataMenor: function(data1,data2) {
        
          if((typeof data1 == "string") && (typeof data2 == "string" )){
            if( data1.length == 10 && data2.length == 10){
              if(data2 < data1){
                return ['Data final menor que data inicial']
              }else{
                return [true]
              }
            }else{
              return [true]
            }
          }else{
            return [true]
          }

        },

        dataValida: function(minha_data){

          let resultado = true;
          let mensagem = '';
          
          if(minha_data != null){
            let ano = parseInt(minha_data.substr(0,4));
            let mes = parseInt(minha_data.substr(5,2));
            let dia = parseInt(minha_data.substr(8,2));

              
            if(ano < 1500 || ano > 2200){
              resultado = false;
              mensagem = 'Data inválida';
            }

            if(mes == 0 || mes > 12){
              resultado = false;
              mensagem = 'Data inválida';
            }

            if(dia == 0){
              resultado = false;
              mensagem = 'Data inválida';
            }

            let meses_30 = [4,6,9,11];

            if(dia > 31){
              resultado = false;
              mensagem = 'Data inválida';
            }else if(meses_30.indexOf(mes) > -1 && dia >30){
              resultado = false;
              mensagem = 'Data inválida';
            }else if(mes == 2 && dia > 29){
              resultado = false;
              mensagem = 'Data inválida';
            }
          }
          

          return [resultado || mensagem]
        },


        IdadeTitular: function(data_nascimento) {
          this.menu_aniv = false;

          if(this.idade(data_nascimento) < 18){
            this.titulo = 'Atenção';
            this.mensagem = 'Esta data de nascimento indica uma pessoa com idade inferior a 18 anos. Verifique se esta informação está correta.';
            this.cor_dialogo ="#455A64";
            this.simnao = false;
            this.dialog = true;   
          }

          if(this.idade(data_nascimento) > 80){
            this.titulo = 'Atenção';
            this.mensagem = 'Esta data de nascimento indica uma pessoa com idade superior a 80 anos. Verifique se esta informação está correta.';
            this.cor_dialogo ="#455A64";
            this.simnao = false;
            this.dialog = true;   
          }

        },


        IdadeParente: function(parentesco, data_titular, data_depend) {

          if(parentesco == 7){
            let ano_aux = parseInt(data_titular.substring(0,4))+10;
            data_titular = ano_aux+'-'+data_titular.substring(5,7)+'-'+data_titular.substring(8,10);
            if(data_depend < data_titular){
              this.titulo = 'Atenção';
              this.mensagem = 'Diferença de idade entre pai(titular) e filho(dependente) inferior a 10 anos. Verifique se esta informação está correta.';
              this.simnao = false;
              this.cor_dialogo ="#455A64";
              this.dialog = true;   
            }
          }

          if(parentesco == 5){
            
            let ano_aux = parseInt(data_titular.substring(0,4))-18;
            data_titular = ano_aux+'-'+data_titular.substring(5,7)+'-'+data_titular.substring(8,10);

            if(data_depend > data_titular){
              this.titulo = 'Atenção';
              this.mensagem = 'Diferença de idade entre pai(dependente) e filho(titular) inferior a 18 anos. Verifique se esta informação está correta.';
              this.simnao = false;
              this.cor_dialogo ="#455A64";
              this.dialog = true; 
            }
          }

          if(parentesco > 0 && parentesco < 5){
            
            let ano_aux = parseInt(data_titular.substring(0,4))-20;
            let data_aux = ano_aux+'-'+data_titular.substring(5,7)+'-'+data_titular.substring(8,10);

            if(data_depend < data_aux){
              this.titulo = 'Atenção';
              this.mensagem = 'Diferença de idade superior a 20 anos. Verifique se esta informação está correta.';
              this.simnao = false;
              this.cor_dialogo ="#455A64";
              this.dialog = true; 
            }

            ano_aux = parseInt(data_titular.substring(0,4))+20;
            data_aux = ano_aux+'-'+data_titular.substring(5,7)+'-'+data_titular.substring(8,10);

            if(data_depend > data_aux){
              this.titulo = 'Atenção';
              this.mensagem = 'Diferença de idade superior a 20 anos. Verifique se esta informação está correta.';
              this.simnao = false;
              this.cor_dialogo ="#455A64";
              this.dialog = true; 
            }


          }

        },


        Bigamia: function(){
          let soma = 0;
          for (let x = 0; x < this.dependentes.length; x++) {
            if(this.dependentes[x].id_parentesco == 1 || this.dependentes[x].id_parentesco == 3){
              soma = soma + 1;
            }
          } 

          if(soma > 1){
            this.titulo = 'Atenção';
            this.mensagem = 'Existem multiplos cônjuges ou companheiros cadastrados. '+ 
                            'Verifique se esta informação está correta.';
            this.cor_dialogo ="#455A64";
            this.simnao = false;
            this.dialog = true;
          }

        },


        lgbt: function(sexo, parentesco){
          if(parentesco > 0 && parentesco < 5){
            if(sexo == this.participante.sexo){
              this.titulo = 'Atenção';
              this.mensagem = 'Sexo do cônjuge ou companheiro igual ao sexo do titular. Verifique se esta informação está correta.';
              this.cor_dialogo ="#455A64";
              this.simnao = false;
              this.dialog = true;
            }
          }
          
        },


        IdadeAdmissao: function(data_admissao) {

          if(data_admissao != null){
            if(this.idadeNaAdmissao(this.participante.data_nascimento, data_admissao) < 16){
              this.titulo = 'Atenção';
              this.mensagem = 'Esta data de admissão indica que a pessoa tinha idade inferior a 16 anos na admissão. Verifique se esta informação está correta.';
              this.cor_dialogo ="#455A64";
              this.simnao = false;
              this.dialog = true;   
            }
          }
        },


        DataMaiorQAtual:  function(data1){
            let current = new Date();
            let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
            return (data1 <= cDate) || 'Data superior a data atual'
        },


        cpf: function(cpf){
          let valido = this.validarCPF(cpf);
          return valido || 'CPF inválido'
        },


        VerificaPais: function(){

          if(this.participante.id_pais != 31){
             this.participante.cep = '';
             this.participante.estado = '';
          }
        },


        Estado: function(){

          let resultado = true;
          let mensagem = '';

          if(this.participante.id_pais == 31){
             
             if(this.participante.estado == ''){
                resultado = false;
                mensagem = 'Obrigatório';
             }
          }

          return resultado || mensagem 
        },

        validaPep: function(){

          if(this.participante.politicamente_exposto == "S"){
            if(this.peps.length == 0){
              this.regraPep = ['É obrigatório adicionar os detalhes'];
            }else{
              this.regraPep = [true];
            }
          }else{
              this.peps = [];
              this.regraPep = [true];
          }
          
          
           
        },


        cpf: function(cpf){
          let valido = this.validarCPF(cpf);
          return valido || 'CPF inválido'
        },

        maxVariavel: function(valor){

            let resultado = true;
            let mensagem = '';

            if(valor < 0 || valor > 15){
              resultado = false;
              mensagem = 'Máximo 15%';
            }

            return resultado || mensagem;
        },

        espontanea: function(){

            let resultado = true;
            let mensagem = '';

            if(this.participante.valor_espontanea > 0 && this.participante.percentual_variavel < 15){
              resultado = false;
              mensagem = 'Somente informe um valor para contribuição espontânea se a contribuição variável for 15%';
            }

            return resultado || mensagem;
        },


        FiltraDocsObrigatorios: function(id_tipo_requerimento){ 
          
          

          var requerimento = this.requerimento;
          var id_plano = 2;

          return this.documentosNecessarios.filter(function(documento) {
            var exibe = false;
            if(documento.id_tipo_requerimento == id_tipo_requerimento ) {
              
                exibe = true;
            }
            return exibe;
          })
        },
        
        
        //#####################
        //Filtro de dependentes
        //####################
        FiltraPlanoDependente: function(id_dependente){
          return this.dependentes_planos.filter(function(plano) {
            return plano.id_dependente == id_dependente;
          })
        },
        //#####################
        //click dos botões da tela
        //####################
        AddEmpresa(){
          this.empresas.push({id: Math.round(Math.random()*-10000,0), 
                              nome_empresa:"", 
                              id_tipo_tempo_servico:null, 
                              data_admissao:"", 
                              data_demissao:""
                              });
        },

        DelEmpresa(id){

          var index =  this.empresas.map(x => {
            return x.id;
          }).indexOf(id); 

          this.empresas.splice(index, 1);
        },

        AddPep(){
          this.peps.push({  
                            id: Math.round(Math.random()*-10000,0), 
                            data_inicio: "", 
                            data_fim: "", 
                            id_parentesco: null,  
                            pessoa: "",
                            ocupacao: "",
                            cpf:"",
                          }); 
          
          this.validaPep();

        },

        DelPep(id){

          var index =  this.peps.map(x => {
            return x.id;
          }).indexOf(id); 

          this.peps.splice(index, 1);

          this.validaPep();
        },

        AddDependente(){

          let id_dependente = Math.round(Math.random()*-10000,0);
          
          if(this.dependentes == null){
            this.dependentes = [];
          }

          this.dependentes.push({ id_dependente: id_dependente,
                                  nome: "",
                                  cpf: "",
                                  id_parentesco: null,
                                  sexo: "",
                                  data_nascimento: "",
                                  beneficiario: "",
                                  designado: "",
                                  percentual: "0"
          });

          
        },


        DelDependente(id){

          for( var i = 0; i < this.dependentes_planos.length; i++){                       
              if ( this.dependentes_planos[i].id_dependente === id) { 
                this.dependentes_planos.splice(i, 1); 
                  i--; 
              }
          }

          var index =  this.dependentes.map(x => {
            return x.id_dependente;
          }).indexOf(id); 

          this.dependentes.splice(index, 1);
        },



        
        submitForm (situacao) {
          this.form_valido = false;
          this.overlay = true;
          
          if(this.idade(this.participante.data_nascimento) > 17){
            this.confirmou_idade = true;
          }else{
            this.confirmou_idade = false; 
          }

          this.validaPep();


          if(this.$refs.form.validate() ){

            this.idade_titular = this.idade(this.participante.data_nascimento);
            //ENVIA OS DADOS DO FORMULÁRIO PARA A API

            if (this.dependentes == null){
              this.dependentes = [];
            }

            if (this.dependentes_planos == null){
              this.dependentes_planos = [];
            }

            this.requerimento.situacao = situacao;


            let data = { 
              requerimento: this.requerimento,
              participante: this.participante,
              empresas: this.empresas,
              peps: this.peps,
              dependentes: this.dependentes,
              usuario: this.participante.cpf,
              email: this.participante.email_pessoal,
            };


            this.$store.state.API.post( '/api/requerimento/salvaradesaops2', data)
            .then(response => {
              if(response.data.codigo =='ok'){
                this.overlay = false;
                this.requerimento.id = response.data.id_requerimento;
                this.documentosNecessarios = response.data.requerimento_docs;
                if(situacao == 'L'){
                  this.alerta = 'O requerimento foi salvo com sucesso! Envie agora os documentos necessários!' ;
                  this.alerta_color ="green";
                  this.exibe_alerta = true; 
                }else{
                  this.$store.commit('setalerta','Requerimento enviado para análise!');
                  this.$router.push('/');
                }
                
                
              }else{
                
                this.titulo = 'Alerta';
                this.mensagem = 'Não foi possível salvar o requerimento.';
                this.cor_dialogo = '#B00020';
                this.simnao = false;
                this.dialog = true;
              }
            })
            .catch(error => {
                    //Erro ao recuperar os dados da pessoa
                    this.TrataErros(error);
            }); 
            
          }else{
            this.form_valido = true;
            this.overlay = false;
            this.titulo = 'Atenção';
            this.mensagem = 'Existem problemas no preenchimento. Favor revisar o formulário.';
            this.cor_dialogo ="#455A64";
            this.simnao = false;
            this.dialog = true;
          }

        },


         BtnOk(){

          if(this.titulo == 'Atenção'){
            this.dialog = false;
            this.titulo = '';
            this.mensagem = '';
          }else{
            this.dialog = false;
            this.titulo = '';
            this.mensagem = '';
            this.sair();
          }
          
          
        }, 

        BtnSim(){

          if(this.titulo == 'Sucesso'){
            this.dialog = false;
            this.titulo = '';
            this.mensagem = '';
            this.sair();
          }

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          
          
           
        }, 


        BtnNao(){

          this.dialog = false;
          this.overlay = false;
          this.titulo = '';
          this.mensagem = '';
             
          
        },   

        sair(){


          this.$router.push('/');


        }, 
        
        btnsair(){


          this.titulo = 'Sucesso';
          this.mensagem = 'Deseja sair do requerimento?';
          this.cor_dialogo ="#455A64";
          this.simnao = true;
          this.dialog = true; 


        }, 
 
        //#####################
        //chamadas nas APIs
        //####################
        consultaCEP(cep, pessoa) {
          //busca o cep
          if(cep.length == 8){
            this.axios({
              method: 'get',
              url: 'https://viacep.com.br/ws/'+cep+'/json/',
            })
            .then(response => {
                        if("cep" in response.data){
                            pessoa.endereco = response.data.logradouro.toUpperCase();
                            pessoa.estado = response.data.uf;
                            pessoa.cidade = response.data.localidade.toUpperCase();
                            pessoa.bairro = response.data.bairro.toUpperCase();

                        }
            })
            .catch(error => {
                    //Erro ao recuperar os dados da pessoa
                    this.TrataErros(error);
            }); 
          }

        },



        recuperaTabelas() {

          this.loading = true;

          this.$store.state.API.get( '/api/adesaops2')
          .then(response => {
              //this.planos = response.data.plano;             
              this.nacionalidade = response.data.nacionalidade;
              this.estado_civil = response.data.estadoCivil; 
              this.escolaridade = response.data.escolaridade;
              this.tipoTempoServico = response.data.tipoTempoServico;
              this.parentesco = response.data.parentesco;
              this.paises = response.data.paises; 
              this.regionais = response.data.regionais;
              
              this.loading = false;
  
          })
          .catch(error => {
                  //Erro ao recuperar os dados da pessoa
                  this.TrataErros(error);
          }); 

        },


        CarregaRequerimento(id_requerimento) {
          
          this.loading = true;
          //busca a pessoa

          let data = {
            id_requerimento: parseInt(id_requerimento),
          };

        

          this.$store.state.API.post( '/api/requerimento/recuperaadesaops2', data)
          .then(response => {

            this.requerimento = response.data.requerimento;           

            //##################################
            //preenche os dados da pessoa
            //##################################
            this.participante = response.data.pessoa; 
            this.participante.data_nascimento = this.participante.data_nascimento.substr(0,10);
            this.participante.id_nacionalidade = parseInt(this.participante.id_nacionalidade);
            this.participante.id_estado_civil = parseInt(this.participante.id_estado_civil);
            this.participante.id_escolaridade = parseInt(this.participante.id_escolaridade);
            this.participante.id_emp = parseInt(this.participante.id_emp);
            delete this.participante.created_at;
            delete this.participante.updated_at;

            
            this.empresas = [];
            let len = response.data.empresas.length;
            for(let i=0; i<len; i++){
              let data_aux = "";
              if(response.data.empresas[i].data_demissao != null){
                data_aux = response.data.empresas[i].data_demissao.substr(0,10);
              }
              this.empresas.push({id: response.data.empresas[i].id, 
                              nome_empresa: response.data.empresas[i].nome, 
                              id_tipo_tempo_servico: parseInt(response.data.empresas[i].id_tipo_servico), 
                              data_admissao: response.data.empresas[i].data_admissao.substr(0,10),
                              data_demissao: data_aux,
                              });
            }

            
            this.peps = response.data.peps;

            len = response.data.peps.length;
            for(let i=0; i<len; i++){
              this.peps[i].id_parentesco = parseInt(response.data.peps[i].id_parentesco);
            }

            this.dependentes = response.data.dependentes;

            len = response.data.dependentes.length;
            for(let i=0; i<len; i++){
              this.dependentes[i].data_nascimento = response.data.dependentes[i].data_nascimento.substr(0,10);
              this.dependentes[i].id_parentesco = parseInt(response.data.dependentes[i].id_parentesco);
            }

            if(this.participante.id_pais == null){
              this.participante.id_pais = 31;
            }else{
              this.participante.id_pais = parseInt(this.participante.id_pais);
            }

            this.documentosNecessarios = response.data.requerimento_docs;

            if(this.participante.data_admissao == null){
              this.tem_data_admissao = false;
            }else{
              this.tem_data_admissao = true;
            }

            this.existe_trust = this.participante.existe_trust;

            this.VerificaSePodeEnviar();
                              
          }) 
          .catch(error => {
                  //Erro ao recuperar os dados da pessoa
                  this.TrataErros(error);
          }); 
        },

        CarregaPessoa() {
          
          this.loading = true;
          //busca a pessoa

          let data = {
                    cpf: this.participante.cpf,
                };

          

          this.$store.state.API.post( '/api/pessoafisica', data)
          .then(response => {

              //##################################
              //preenche os dados da pessoa
              //##################################
              this.participante.nome_mae = '';
              this.participante.cep = '';

              this.participante = response.data.pessoa_fisica; 
              
              this.participante.data_nascimento = this.participante.data_nascimento.substr(0,10);
              if(this.participante.id_nacionalidade != null){
                this.participante.id_nacionalidade = parseInt(this.participante.id_nacionalidade);
              }
              if(this.participante.id_estado_civil != null){
                this.participante.id_estado_civil = parseInt(this.participante.id_estado_civil);
              }
              if(this.participante.id_escolaridade != null){
                this.participante.id_escolaridade = parseInt(this.participante.id_escolaridade);
              }

              if(this.participante.id_pais == null){
                this.participante.id_pais = 31;
              }else{
                this.participante.id_pais = parseInt(this.participante.id_pais); 
              }  

              if(this.participante.data_admissao == null){
                this.tem_data_admissao = false;
              }else{
                this.tem_data_admissao = true;
              }

              this.participante.existe_trust = response.data.existe_trust;
              this.participante.percentual_variavel = 15;

              let qtd_emp = response.data.tempo_servico.length;

              this.empresas = [];
              for(let i=0; i<qtd_emp; i++){

                this.empresas.push({
                                    id: i, 
                                    nome_empresa: response.data.tempo_servico[i].nm_empresa, 
                                    id_tipo_tempo_servico: parseInt(response.data.tempo_servico[i].ic_atividade), 
                                    data_admissao: response.data.tempo_servico[i].dt_ini.substr(0,10), 
                                    data_demissao: typeof response.data.tempo_servico[i].dt_fim == "string" ? response.data.tempo_servico[i].dt_fim.substr(0,10) : "",
                                  });

              }

              
             
                              
          }) 
          .catch(error => {
                  //Erro ao recuperar os dados da pessoa
                  this.TrataErros(error);
          });  

          
        },



        //#####################
        //funções utilitárias
        //####################
        idade(data_aniversario = '') {
          var d = new Date,
              ano_atual = d.getFullYear(),
              mes_atual = d.getMonth() + 1,
              dia_atual = d.getDate(),
              ano_aniversario = data_aniversario.substring(0,4),
              mes_aniversario = data_aniversario.substring(5,7),
              dia_aniversario = data_aniversario.substring(8,10),
              quantos_anos = ano_atual - ano_aniversario;

          if (mes_atual < mes_aniversario || mes_atual == mes_aniversario && dia_atual < dia_aniversario) {
              quantos_anos--;
          }
          quantos_anos = parseInt(quantos_anos);

          return quantos_anos < 0 ? 0 : quantos_anos;
        },


        idadeNaAdmissao(data_aniversario = '', data_admissao) {

          var ano_admissao = data_admissao.substring(0,4),
              mes_admissao = data_admissao.substring(5,7),
              dia_admissao = data_admissao.substring(8,10),
              ano_aniversario = data_aniversario.substring(0,4),
              mes_aniversario = data_aniversario.substring(5,7),
              dia_aniversario = data_aniversario.substring(8,10),
              quantos_anos = ano_admissao - ano_aniversario;

          if (mes_admissao < mes_aniversario || mes_admissao == mes_aniversario && dia_admissao < dia_aniversario) {
              quantos_anos--;
          }
          quantos_anos = parseInt(quantos_anos);

          return quantos_anos < 0 ? 0 : quantos_anos;
        },


        validarCPF(cpf) {	
          if(cpf){
            cpf = cpf.replace(/[^\d]+/g,'');	
            if(cpf == '') return false;	
            // Elimina CPFs invalidos conhecidos	
            if (cpf.length != 11 || 
              cpf == "00000000000" || 
              cpf == "11111111111" || 
              cpf == "22222222222" || 
              cpf == "33333333333" || 
              cpf == "44444444444" || 
              cpf == "55555555555" || 
              cpf == "66666666666" || 
              cpf == "77777777777" || 
              cpf == "88888888888" || 
              cpf == "99999999999")
                return false;		
            // Valida 1o digito	
            let add = 0,
                i = 0,
                rev = 0;	

            for (i=0; i < 9; i ++)		
              add += parseInt(cpf.charAt(i)) * (10 - i);	
              rev = 11 - (add % 11);	
              if (rev == 10 || rev == 11)		
                rev = 0;	
              if (rev != parseInt(cpf.charAt(9)))		
                return false;		
            // Valida 2o digito	
            add = 0;	
            for (i = 0; i < 10; i ++)		
              add += parseInt(cpf.charAt(i)) * (11 - i);	
            rev = 11 - (add % 11);	
            if (rev == 10 || rev == 11)	
              rev = 0;	
            if (rev != parseInt(cpf.charAt(10)))
              return false;
          }
          		
          return true;   
        },


        formatDate (date) {
          if (!date) return null

          const [year, month, day] = date.split('-')
          return `${day}/${month}/${year}`
        },

        
        addTeste() {
          this.teste = this.teste +1;
        },


        EscolheOuEnvia(id_tipo_documento, id_req_doc){

          this.exibe_escolha = true;
          this.id_tipo_documento = id_tipo_documento;
          this.id_req_doc = id_req_doc;
          this.id_req_bnf_doc = null;
          this.id_dependente = null;
        },


        CarregaArquivo(){

          if(this.escolhido == 1){
            this.selecionarArquivo();
          }else{
                this.$router.push('/docsportipo/'+this.id_tipo_documento+'/'+this.requerimento.id+'/'+this.id_req_doc+'/3');
          }

        },


        selecionarArquivo() {
          document.getElementById("fileUpload1").click();
        },


        enviarArquivo() {

          if(event.target.files[0].size <= this.$store.state.max_file_size * 1048576 ){

            this.loading = true;

            let formData = new FormData();
            formData.append('id_nuvem', this.$store.state.nuvem.id);
            formData.append('id_tipo', 405);
            formData.append('id_tipo_documento',this.id_tipo_documento); 
            formData.append('id_protocolo_ged',this.$store.state.nuvem.id_protocolo_ged);
            formData.append('token_ged',this.$store.state.token_ged);
            formData.append('arquivo', this.arquivo);
            formData.append('usuario', this.$store.state.cpf);
            formData.append('id_origem', 1);
            formData.append('escopo', 'P');


            this.$store.state.API.post( '/api/novodocumento',  formData)
            .then(response => {
              if(response.data.codigo == 'ok'){
                  this.atualizaDocumento(this.id_req_doc, response.data.id_documento);
              }
            })
            .catch(error => {
                this.TrataErros(error);

            });  
          }else{
            this.alerta = 'Documento muito grande! Tamanho máximo: '+this.$store.state.max_file_size+' MB';
            this.alerta_color = '#B00020';
            this.exibe_alerta = true;
          }

        },

        atualizaDocumento(id_req_doc, id_documento){


          let data = {
                id_req_doc: id_req_doc,
                id_documento: id_documento,
                id_situacao: 4,
                usuario: this.$store.state.cpf,
                id_nuvem: this.$store.state.nuvem.id
              };


          this.$store.state.API.post( '/api/requerimento/atualizadocumento',  data)
          .then(response => {
              if(response.data.codigo == 'ok'){

                this.alerta = 'Documento gravado com sucesso!';
                this.alerta_color = "green";

                const index = this.documentosNecessarios.findIndex((documento, index) => {
                  if (documento.id_req_doc == this.id_req_doc) {
                    return true
                  }
                });

                this.documentosNecessarios[index].arquivo = this.arquivo.name;
                this.documentosNecessarios[index].desc_situacao = "EM ANÁLISE";
                this.documentosNecessarios[index].id_documento = id_documento;
                this.documentosNecessarios[index].id_situacao = 4;

                this.VerificaSePodeEnviar();

                this.exibe_escolha = false;
                this.id_tipo_documento = null;
                this.id_req_doc = null;  
                this.exibe_alerta = true; 
                this.arquivo = null; 
                this.loading  = false;    
        
              }else{
                this.loading = false;
              }
              
          })
          .catch(error => {
              this.TrataErros(error);
          });

        },


        VerificaSePodeEnviar(){
        
          this.pode_enviar = false;

          if(this.requerimento.situacao == 'L' || this.requerimento.situacao == 'E'){

            var pode = true;
            var documentos = this.FiltraDocsObrigatorios(this.requerimento.id_tipo_requerimento);
            for (var i = 0; i < documentos.length; i++) {
                if(documentos[i].id_situacao != 4 && documentos[i].id_situacao != 1){
                  if(documentos[i].id_tipo_documento != 15){
                    pode = false;
                  }
                }
            }

            

          }

          this.pode_enviar = pode;
         
        },


        TrataErros(error){

          

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.simnao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if(typeof error.response === 'undefined'){
            console.log('Não retornou mensagem');
          }else{

            console.log(error.response);

            if('data' in error.response){
              if('mensagem' in error.response.data){
                if( error.response.data.mensagem == 'Token expirado'){
                  token_expirado = true;
                }  
              }

              if(token_expirado){
                this.mensagem = 'Sua sessão expirou. Faça um novo login.';
              } 
            }

            this.dialog = true;

            var usuario = {
                logado: false,
                nome: '',
                id: '',
                email: '',
                matricula: '',
                id_setor: ''
            };

            this.$store.commit('setusuariologado', usuario);
          }
          
        } 
      },
   }
</script>