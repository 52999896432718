<template>
  <div>
    <br>
    <v-row>
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-text-field
          type="number"
          hide-spin-buttons
          :value = "value.cpf_participante_peculio"
          @input = "setParticipantePeculio"
          label = "CPF do participante"
          :rules="[...Rules.Required, ...maxLength(11), ...cpf(value.cpf_participante_peculio)]"
          :readonly="situacao != 'L'"
          @keypress="SomenteInteiros()"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="my-0 py-0">
        <v-text-field
          :value = "nome_participante"
          label = "Nome do participante"
          readonly
          hide-details
          background-color="grey lighten-2"
        >
        </v-text-field>
      </v-col>
      
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-select
          :value = "value.id_plano"
          @input = "setIdPlano"
          label="Plano"
          :items="planos_participante"
          item-text="descricao"
          item-value="id"
          :rules="Rules.Required"  
          :readonly="situacao != 'L'"
          no-data-text="Informe primeiro o CPF do participante"
          
        > 
        </v-select>
      </v-col> 

    </v-row>

    <v-row>
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-select
          label="Tipo de pagamento"
          :value="value.tipo_pagamento"
          @input="setTipoPagamento"
          :items="$store.state.tipos_pagamento"
          item-text="descricao"
          item-value="id"
          :rules="Rules.Required" 
          :readonly="situacao != 'L'"
        > 
        </v-select>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-select
          label="Banco"
          :value="value.banco"
          @input="setBanco"
          :items="bancos"
          item-text="nome"
          item-value="numero"
          :rules="Rules.Required" 
          :readonly="situacao != 'L'"
        > 
        </v-select>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-text-field
          :value = "value.agencia"
          @input = "setAgencia"
          label="Agencia"
          :rules="Rules.Required" 
          :readonly="situacao != 'L'"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-text-field
          :value = "value.conta"
          @input = "setConta"
          label="Conta"
          :rules="Rules.Required" 
          :readonly="situacao != 'L'"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>

      <v-col cols="12" md="6" class="my-0 py-0">
         <v-select
          label="Deseja informar representante legal?"
          :value="value.representante_legal"
          @input="setRepresentanteLegal"
          :items="sim_nao"
          item-text="descricao"
          item-value="id"
          :rules="Rules.Required" 
          :readonly="situacao != 'L'"
        > 
        </v-select>
      </v-col>

    </v-row>

    <v-row>
      <v-col cols="12" md="6" class="my-0 py-0" v-if="representante_legal == 'S'">
        <v-text-field
          label="Nome do Representante"
          :value = "value.nome_representante"
          @input = "setNomeRepresentante"
          :rules="Rules.Required" 
          :readonly="situacao != 'L'"
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" md="3" class="my-0 py-0" v-if="representante_legal == 'S'">
        <v-text-field
          label="CPF"
          :value = "value.cpf_representante"
          @input = "setCpf"
          :rules="[...Rules.Required, ...maxLength(11), ...cpf(value.cpf_representante)]"
          :readonly="situacao != 'L'"
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" md="3" class="my-0 py-0" v-if="representante_legal == 'S'">
        <v-text-field
          label="Identidade do Representante"
          :value = "value.numero_identidade"
          @input = "setIdentidade"
          :rules="Rules.Required" 
          :readonly="situacao != 'L'"
        >
        </v-text-field>
      </v-col>


    </v-row>


    <v-row>
      <v-col cols="12" md="3" class="my-0 py-0" v-if="representante_legal == 'S'">
        <v-text-field
          label="Órgão emissor"
          :value = "value.orgao_emissor"
          @input = "setOrgaoEmissor"
          :rules="Rules.Required" 
          :readonly="situacao != 'L'"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0" v-if="representante_legal == 'S'">
        <v-text-field
          label="Data de expedição"
          type="date" 
          :value = "value.data_expedicao"
          @input = "setData"
          :rules="Rules.Required" 
          :readonly="situacao != 'L'"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0" v-if="representante_legal == 'S'">
        <v-select
          label="UF"
          :value="value.uf"
          @input="setUf"
          :items="estados"
          :rules="Rules.Required" 
          :readonly="situacao != 'L'"
        > 
        </v-select>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0" v-if="representante_legal == 'S'">
        <v-select
          label="Qualidade do representante"
          :value="value.qualidade_representante"
          @input="setQualidade"
          :items="qualidade"
          item-text="descricao"
          item-value="id"
          :rules="Rules.Required" 
          :readonly="situacao != 'L'"
        > 
        </v-select>
      </v-col>

    </v-row>

    

    
  </div>  
</template>    

<script>
  
    export default {  
        name: "ReqPeculio",
        props: ['value','situacao'], 
        data: function(){
          return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            bancos: [
              {numero:"001", nome: "BANCO DO BRASIL"},
              {numero:"104", nome: "CAIXA ECONÔMICA FEDERAL"},
              {numero:"237", nome: "BRADESCO"},
              {numero:"341", nome: "ITAÚ"},
              {numero:"033", nome: "SANTANDER"}
            ],
            sim_nao: [
              {id:"S", descricao: "SIM"},
              {id:"N", descricao: "NÃO"}
            ],
            qualidade: [
              {id:1, descricao: "MÃE/PAI"},
              {id:2, descricao: "TUTOR"},
              {id:3, descricao: "CURADOR"},
              {id:4, descricao: "PROCURADOR"}
            ],
            estados:["AC", "AL",	"AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"],
            tipo_pagamento: this.value.tipo_pagamento,
            banco: this.value.banco,
            agencia: this.value.agencia,
            conta: this.value.conta,
            cpf_participante_peculio: this.value.cpf_participante_peculio,
            nome_participante: '',
            id_plano: this.value.id_plano,
            planos_participante: [],
            representante_legal: this.value.representante_legal,
            nome_representante: this.value.nome_representante,
            cpf_representante: this.value.cpf_representante,
            numero_identidade: this.value.numero_identidade,
            orgao_emissor: this.value.orgao_emissor,
            data_expedicao: this.value.data_expedicao,
            uf: this.value.uf,
            qualidade_representante: this.value.qualidade_representante,
            nome_participante: '',
            possui_certidao: this.value.possui_certidao,
          }
        },  
        created: function() {
          this.recuperaNome();
        },
        methods: {

          maxLength: function(max) {
            return [v => (v || '' ).length <= max || 'Máximo '+max+' caracteres']
          },


          cpf: function(cpf){
            let valido = this.validarCPF(cpf);
            return valido || 'CPF inválido'
          },


          FiltraBeneficio: function(id_plano){
          
          return this.$store.state.beneficios.filter(function(beneficio) {
            var exibe = false;
            if(beneficio.id_plano == id_plano ) {
              exibe = true;
            }
            return exibe;
          })
        },
          updateValue() {
           //console.log(this.beneficio);  
            this.$emit('input', {
               tipo_pagamento: this.tipo_pagamento,
               banco: this.banco,
               agencia: this.agencia,
               conta: this.conta,
               cpf_participante_peculio: this.cpf_participante_peculio,
               id_plano: this.id_plano,
               representante_legal: this.representante_legal,
               nome_representante: this.nome_representante,
               cpf_representante: this.cpf_representante,
               numero_identidade: this.numero_identidade,
               orgao_emissor: this.orgao_emissor,
               data_expedicao: this.data_expedicao,
               uf: this.uf,
               qualidade_representante: this.qualidade_representante,
               possui_certidao: this.possui_certidao,
            });
          },
          setBanco(banco){
           this.banco = banco;
           this.updateValue();
          },
          setAgencia(agencia){
           this.agencia = agencia;
           this.updateValue();
          },
          setConta(conta){
           this.conta = conta;
           this.updateValue();
          },
          setTipoPagamento(tipo_pagamento){
           this.tipo_pagamento = tipo_pagamento;
           this.updateValue();
          },
          setParticipantePeculio(cpf_participante_peculio){
           this.cpf_participante_peculio = cpf_participante_peculio;
           this.updateValue();
           if(cpf_participante_peculio.length == 11){
            this.recuperaNome();
           }
          },
          setIdPlano(id_plano){
           this.id_plano = id_plano;
           this.updateValue();
          },
          setRepresentanteLegal(representante_legal){
           this.representante_legal = representante_legal;
           this.updateValue();
          },
          setNomeRepresentante(nome_representante){
           this.nome_representante = nome_representante;
           this.updateValue();
          },
          setCpf(cpf_representante){
           this.cpf_representante = cpf_representante;
           this.updateValue();
          },
          setIdentidade(numero_identidade){
           this.numero_identidade = numero_identidade;
           this.updateValue();
          },
          setOrgaoEmissor(orgao_emissor){
           this.orgao_emissor = orgao_emissor;
           this.updateValue();
          },
          setData(data_expedicao){
           this.data_expedicao = data_expedicao;
           this.updateValue();
          },
          setUf(uf){
           this.uf = uf;
           this.updateValue();
          },
          setQualidade(qualidade_representante){
           this.qualidade_representante = qualidade_representante;
           this.updateValue();
          },
          setPossuiCertidao(possui_certidao){
           this.possui_certidao = possui_certidao;
           this.updateValue();
          },


          validarCPF(cpf) {	

            if(cpf == null){
              cpf = "";
            }  

            cpf = cpf.replace(/[^\d]+/g,'');	
            if(cpf == '') return false;	
            // Elimina CPFs invalidos conhecidos	
            if (cpf.length != 11 || 
              cpf == "00000000000" || 
              cpf == "11111111111" || 
              cpf == "22222222222" || 
              cpf == "33333333333" || 
              cpf == "44444444444" || 
              cpf == "55555555555" || 
              cpf == "66666666666" || 
              cpf == "77777777777" || 
              cpf == "88888888888" || 
              cpf == "99999999999")
                return false;		
            // Valida 1o digito	
            let add = 0,
                i = 0,
                rev = 0;	

            for (i=0; i < 9; i ++)		
              add += parseInt(cpf.charAt(i)) * (10 - i);	
              rev = 11 - (add % 11);	
              if (rev == 10 || rev == 11)		
                rev = 0;	
              if (rev != parseInt(cpf.charAt(9)))		
                return false;		
            // Valida 2o digito	
            add = 0;	
            for (i = 0; i < 10; i ++)		
              add += parseInt(cpf.charAt(i)) * (11 - i);	
            rev = 11 - (add % 11);	
            if (rev == 10 || rev == 11)	
              rev = 0;	
            if (rev != parseInt(cpf.charAt(10)))
              return false;		
            return true;   
            
            
          },



          recuperaNome(){


            if(this.cpf_participante_peculio.length == 11){

              let data = {
                    cpf: this.cpf_participante_peculio
              };

              this.$store.state.API.post( '/api/requerimento/nomepessoa',  data)
              .then(response => {
                  this.nome_participante = response.data;
              })
              .catch(error => {
                  console.log(error);
              });

              this.recuperaPlanosParticipante();
            }else{
              this.planos_participante = [];
              this.id_plano = null;
            }

          },



          recuperaPlanosParticipante: function() {

            let data = {
                    cpf: this.cpf_participante_peculio
              };

            

            this.$store.state.API.post( '/api/planosparticipante',  data)
            .then(response => {
                this.planos_participante = response.data.planos_participante;
                if(this.planos_participante.length == 1){
                  this.id_plano = parseInt(this.planos_participante[0].id);
                  this.updateValue();
                }
            })
            .catch(error => {
                console.log(error);
                return [];
            });

          },



        //######################################
        //# permite o input de numeros inteiros apenas
        //###################################### 
        SomenteInteiros: function(evt) {
          this.valor_ok=false;
          this.msg_critica_valor='';

          evt = (evt) ? evt : window.event;
          let expect = evt.target.value.toString() + evt.key.toString();
          
          if (!/^[0-9]*$/.test(expect)) {
            evt.preventDefault();
          } else {
            return true;
          }
        },


        }
    }
</script>