import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'



import Router from './routes.js'
import Store from './store.js'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import './assets/custom.scss'

//import OtpInput from "@bachdgvn/vue-otp-input"

//Vue.component("v-otp-input", OtpInput);

Vue.use(VueAxios, axios)


Vue.config.productionTip = false


new Vue({
  router: Router,
  store: Store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
