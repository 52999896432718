<template>
  <div>
     <v-card>
    <v-card-text>  
    <div>
      <v-row>
        <v-col cols="12" md="11" class="text-left">
            <h2 v-if="tipo==1"> Declaração de Beneficiários necessária ao dimensionamento dos encargos futuros previstos com pensão por morte </h2> 
        </v-col>
        
      </v-row>
    </div>
    <br>
    <v-row>
      <v-col v-if="tipo==1" cols="12" md="12" class="text-justify text-h6 font-weight-light">
        O requerente ao Beneficío de aposentadoria, nos termos do regulamento do Plano de Beneficíos SERPRO II(PS-II), declara que a relação abaixo compreende seu(s) beneficiário(s) nomeado(s), estando ciente que esta declaração destina-se ao cálculo da aposentadoria, de acordo com a base técnica do PS-II vigente no momento da conversão do saldo da conta do Participante em renda e ao dimensionamento dos encargos futuros previstos com pensão por morte. Declara, ainda, que esta informação cancela e substitui toda e qualquer outra anteriormente prestada ao SERPROS sobre o assunto, sendo que ocorrendo a nomeação de novo(s) beneficiário(s) não declarado(s) neste termo, enseja a redução do valor da prestação mensal de aposentadoria na proporção necessária para evitar que a inscrição de beneficiário(s) produza elevação dos encargos do plano. <b>O requerente declara, ainda, estar ciente que somente terão direito a pensão por morte os beneficiários indicados por ele nessa declaração.</b>
      </v-col>
      <v-col v-if="tipo==13" cols="12" md="12" class="text-justify text-h6 font-weight-light">
        Informe abaixo os seus dependentes para fins de cálculo do Imposto de Renda Retido na Fonte.
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" class="text-justify ">
        <h2 v-if="tipo==1">Beneficiários Habilitados: <v-btn class="mb-2 md-4" rounded color="#B0BEC5" @click="AddDependente" > <v-icon>mdi-plus-circle-outline</v-icon></v-btn></h2>
        <h2 v-if="tipo==13">Dependentes: <v-btn class="mb-2 md-4" rounded color="#B0BEC5" @click="AddDependente" > <v-icon>mdi-plus-circle-outline</v-icon></v-btn></h2>
      </v-col>
    </v-row>
    <div
      v-for="(dependente, index) in dependentes"
      :key="index"
    >
      <v-row >
        <v-col cols="12" md="3">
          <v-text-field  
            dense 
            :value="dependente.nome" 
            @input="setNome(index, $event)"
            label="Nome"  
            :rules="[...Rules.Required, ...maxLength(60)]"
            autocomplete="none"
            :readonly="situacao != 'L'"
            >
            </v-text-field>
        </v-col> 
        <v-col cols="12" md="2">
          <v-text-field  
            dense 
            :value="dependente.cpf" 
            @input="setCpf(index, $event)"
            label="CPF"  
            :rules="[...Rules.Required, ...maxLength(11), ...cpf(dependente.cpf)]"
            autocomplete="none"
            :readonly="situacao != 'L'" 
            >
          </v-text-field>
        </v-col> 
        <v-col cols="12" md="2">
          <v-text-field  
              type="date" 
              dense 
              :value="dependente.data_nascimento" 
              @input="setDataNascimento(index, $event)"
              label="Data de nascimento"  
              :rules="[...Rules.Required]" 
              autocomplete="none"
              :readonly="situacao != 'L'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            label="Sexo"
            :value="dependente.sexo"
            @input="setSexo(index, $event)"
            :items="sexo"
            item-text="descricao"
            item-value="id"
            dense
            :rules="[...Rules.Required]"
            :readonly="situacao != 'L'"
          > 
          </v-select>
        </v-col> 
        <v-col cols="12" md="2">
          <v-select
            label="Dependência econômica"
            :value="dependente.dependencia"
            @input="setDependencia(index, $event)"
            :items="dependencia"
            item-text="descricao"
            item-value="id"
            dense
            :rules="[...Rules.Required]"
            :readonly="situacao != 'L'"
          > 
          </v-select>
        </v-col>
        <v-col cols="12" md="1" class="text-right">
          <v-btn rounded color="#B0BEC5" @click="DelDependente(index)" > <v-icon>mdi-minus-circle-outline</v-icon></v-btn>
        </v-col>
      </v-row>
    </div>
    </v-card-text>
    </v-card>
  </div>  
</template>    

<script>
  
    export default {  
        name: "ReqDependentes",
        props: ['value','situacao','docs_obrigatorios', 'tipo'], 
        data: function(){
          return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            dependentes: this.value,
            sexo:[
              {id:'F', descricao:'Feminino'},
              {id:'M', descricao:'Masculino'},
            ],
            dependencia: [
              {id:1, descricao:'Cônjuge ou ex-cônjuge pensionista'},
              {id:2, descricao:'Companheiro(a) em união estável'},
              {id:3, descricao:'Ex-companheiro(a) pensionista'},
              {id:4, descricao:'Filhos, enteados e tutelados solteiros e menores de 21 anos'},
              {id:5, descricao:'Filhos, enteados e tutelados solteiros e inválidos'},
              {id:6, descricao:'Pais dependentes econômicos'},
            ]

          }
        },  
        methods: {
          maxLength: function(max) {
            return [v => (v || '' ).length <= max || 'Máximo '+max+' caracteres']
          },

          cpf: function(cpf){
            let valido = this.validarCPF(cpf);
            return valido || 'CPF inválido'
          },

          validarCPF(cpf) {	
            cpf = cpf.replace(/[^\d]+/g,'');	
            if(cpf == '') return false;	
            // Elimina CPFs invalidos conhecidos	
            if (cpf.length != 11 || 
              cpf == "00000000000" || 
              cpf == "11111111111" || 
              cpf == "22222222222" || 
              cpf == "33333333333" || 
              cpf == "44444444444" || 
              cpf == "55555555555" || 
              cpf == "66666666666" || 
              cpf == "77777777777" || 
              cpf == "88888888888" || 
              cpf == "99999999999")
                return false;		
            // Valida 1o digito	
            let add = 0,
                i = 0,
                rev = 0;	

            for (i=0; i < 9; i ++)		
              add += parseInt(cpf.charAt(i)) * (10 - i);	
              rev = 11 - (add % 11);	
              if (rev == 10 || rev == 11)		
                rev = 0;	
              if (rev != parseInt(cpf.charAt(9)))		
                return false;		
            // Valida 2o digito	
            add = 0;	
            for (i = 0; i < 10; i ++)		
              add += parseInt(cpf.charAt(i)) * (11 - i);	
            rev = 11 - (add % 11);	
            if (rev == 10 || rev == 11)	
              rev = 0;	
            if (rev != parseInt(cpf.charAt(10)))
              return false;		
            return true;   
          },
          updateValue() {
           //console.log(this.dependentes);  
            this.$emit('input', this.dependentes);
          },
          setNome(index,nome){
            //console.log('leo');  
           this.dependentes[index].nome = nome;
           this.updateValue();
          },
          setCpf(index,cpf){
            //console.log('leo');  
           this.dependentes[index].cpf = cpf;
           this.updateValue();
          },
          setDataNascimento(index,data_nascimento){
           this.dependentes[index].data_nascimento = data_nascimento;
           this.updateValue();
          },
          setSexo(index,sexo){
           this.dependentes[index].sexo = sexo;
           this.updateValue();
          },
          setDependencia(index,dependencia){
           this.dependentes[index].dependencia = dependencia;
           this.updateValue();
          },


          AddDependente(){
          
            if(this.dependentes == null){
              this.dependentes = [];
            }

            this.dependentes.push({ id: null,
                                    nome: null,
                                    cpf:null,
                                    data_nascimento: null,
                                    sexo: null,
                                    dependencia: null, 
                                    docs_obrigatorios: this.docs_obrigatorios                  
            });

            this.updateValue();

          },

          DelDependente(index){
            //console.log(index);
            this.dependentes.splice(index, 1);

            this.updateValue();
          },

        }
    }
</script>