<template>
  <div>
    <br>
    <v-row>
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-select
          label="Tipo de pagamento"
          :value="value.tipo_pagamento"
          @input="setTipoPagamento"
          :items="$store.state.tipos_pagamento"
          item-text="descricao"
          item-value="id"
          :rules="Rules.Required" 
          :readonly="situacao != 'L'"
        > 
        </v-select>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-select
          label="Banco"
          :value="value.banco"
          @input="setBanco"
          :items="bancos"
          item-text="nome"
          item-value="numero"
          :rules="Rules.Required" 
          :readonly="situacao != 'L'"
        > 
        </v-select>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-text-field
          :value = "value.agencia"
          @input = "setAgencia"
          label="Agencia"
          :rules="Rules.Required" 
          :readonly="situacao != 'L'"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-text-field
          :value = "value.conta"
          @input = "setConta"
          label="Conta"
          :rules="Rules.Required" 
          :readonly="situacao != 'L'"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6" class="my-0 py-0">
        <v-text-field
          value = "Auxílio-Doença"
          label = "Benefício"
          readonly
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="my-0 py-0">
        <v-select
          label="Aposentado pelo INSS"
          :value="value.aposentado_inss"
          @input="setAposentadoINSS"
          :items="sim_nao"
          item-text="descricao"
          item-value="id"
          :rules="Rules.Required" 
          :readonly="situacao != 'L'"
        > 
        </v-select>
      </v-col>

    </v-row>
  </div>  
</template>    

<script>
  
    export default {  
        name: "ReqAuxilioDoenca",
        props: ['value','id_plano','situacao'], 
        data: function(){
          return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            bancos: [
              {numero:"001", nome: "BANCO DO BRASIL"},
              {numero:"237", nome: "BRADESCO"},
              {numero:"341", nome: "ITAÚ"},
              {numero:"033", nome: "SANTANDER"}
            ],
            sim_nao: [
              {id:"S", descricao: "SIM"},
              {id:"N", descricao: "NÃO"}
            ],
            tipo_pagamento: this.value.tipo_pagamento,
            banco: this.value.banco,
            agencia: this.value.agencia,
            conta: this.value.conta,
            parcela_vista: this.value.parcela_vista,
            aposentado_inss: this.value.aposentado_inss
          }
        },  
        methods: {
          FiltraBeneficio: function(id_plano){
          
          return this.$store.state.beneficios.filter(function(beneficio) {
            var exibe = false;
            if(beneficio.id_plano == id_plano ) {
              exibe = true;
            }
            return exibe;
          })
        },
          updateValue() {
           //console.log(this.beneficio);  
            this.$emit('input', {
               tipo_pagamento: this.tipo_pagamento,
               banco: this.banco,
               agencia: this.agencia,
               conta: this.conta,
               parcela_vista: this.parcela_vista,
               aposentado_inss: this.aposentado_inss,
            });
          },
          setBanco(banco){
           this.banco = banco;
           this.updateValue();
          },
          setAgencia(agencia){
           this.agencia = agencia;
           this.updateValue();
          },
          setConta(conta){
           this.conta = conta;
           this.updateValue();
          },
          setTipoPagamento(tipo_pagamento){
           this.tipo_pagamento = tipo_pagamento;
           this.updateValue();
          },
          setParcela(parcela_vista){
           this.parcela_vista = parcela_vista;
           this.updateValue();
          },
          setAposentadoINSS(aposentado_inss){
           this.aposentado_inss = aposentado_inss;
           this.updateValue();
          },
        }
    }
</script>