var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"timeout":"2000","color":"green"},model:{value:(_vm.exibe_alerta),callback:function ($$v) {_vm.exibe_alerta=$$v},expression:"exibe_alerta"}},[_vm._v(" "+_vm._s(_vm.alerta)+" ")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"elevation":"4"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","outlined":"","label":"Nome","readonly":""},model:{value:(_vm.nome_participante),callback:function ($$v) {_vm.nome_participante=$$v},expression:"nome_participante"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","outlined":"","label":"CPF","readonly":""},model:{value:(_vm.cpf_participante),callback:function ($$v) {_vm.cpf_participante=$$v},expression:"cpf_participante"}})],1)],1)],1)],1),_c('br'),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.form_valido),callback:function ($$v) {_vm.form_valido=$$v},expression:"form_valido"}},[(_vm.loading)?_c('v-row',{staticClass:"mt-5"},[_c('v-col',[_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)])],1):_vm._e(),(_vm.loading)?_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-center"},[_vm._v("Carregando")])])],1):_vm._e(),(!_vm.loading)?_c('v-card',{attrs:{"outlined":"","elevation":"4"}},[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"text-left"},[_c('h4',{staticClass:"text--secondary"},[_vm._v(" Documentos")])])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-2 table",attrs:{"headers":_vm.headerDocumentos,"items":_vm.documentos,"sort-by":"data_envio","sort-desc":"","no-data-text":"Não há nenhum arquivo","items-per-page":10,"loading":_vm.loading.anexo,"loading-text":"Carregando arquivos...","footer-props":{
                    itemsPerPageText: 'Linhas por página',
                    itemsPerPageAllText: 'Todas',
                  }},scopedSlots:_vm._u([{key:"item.data_gravacao",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.data_gravacao).toLocaleString("pt-BR",{dateStyle: "short"})))])]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2 text-center",attrs:{"icon":""},on:{"click":function($event){return _vm.downloadAnexo(item)}}},[_c('v-icon',[_vm._v("mdi-download-circle")])],1)]}},{key:"item.escolher",fn:function(ref){
                  var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2 text-center",attrs:{"small":""},on:{"click":function($event){return _vm.AnexaArquivo(item)}}},[_vm._v("Anexar")])]}}],null,true)})],1)],1):_vm._e(),_c('br'),_c('v-row',[_c('v-col',{staticClass:"text-right"},[(!_vm.loading)?_c('v-btn',{attrs:{"rounded":"","color":"#B0BEC5","to":'/requerimento0'+this.$route.params.tipo_requerimento+'/'+this.$route.params.id_requerimento+'/'+this.$route.params.id_tipo_documento+'/'+3}},[_c('v-icon',[_vm._v("mdi-arrow-left-circle")]),_vm._v(" Cancelar")],1):_vm._e()],1)],1),_c('v-overlay',{attrs:{"absolute":"","value":_vm.dialog}}),_c('v-snackbar',{attrs:{"multi-line":"","centered":"","color":_vm.cor_dialogo,"timeout":"-1","max-width":"400","min-height":"140","vertical":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                  var attrs = ref.attrs;
return [(!_vm.sim_nao)?_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){return _vm.BtnOk()}}},'v-btn',attrs,false),[_vm._v(" Ok ")]):_vm._e(),(_vm.sim_nao)?_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){return _vm.BtnSim()}}},'v-btn',attrs,false),[_vm._v(" Sim ")]):_vm._e(),(_vm.sim_nao)?_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){return _vm.BtnNao()}}},'v-btn',attrs,false),[_vm._v(" Não ")]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_vm._v(" "+_vm._s(_vm.mensagem)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }