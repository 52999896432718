<template>
  <div>
    <span style="color:White;" v-if="this.timeLeft > 600">
      Tempo restante: {{ formattedTimeLeft }}
      <br>
    </span>
    <span style="color:Orange;" v-if="this.timeLeft <= 600">
      Tempo restante: {{ formattedTimeLeft }}
      <br>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    timeLeft: {
      type: Number,
      required: true
    }
  },
  computed: {
    formattedTimeLeft() {
      const timeLeft = this.timeLeft
      // The largest round integer less than or equal 
     //  to the result of time divided being by 60.
      const minutes = Math.floor(timeLeft / 60)
      // Seconds are the remainder of the time divided
      // by 60 (modulus operator)
      let seconds = Math.floor(timeLeft % 60)
      // If the value of seconds is less than 10,
      // then display seconds with a leading zero
      if (seconds < 10) {
        seconds = `0${seconds}`
      }
      // The output in MM:SS format
      return `${minutes}:${seconds}`
    },
  }
}
</script>