<template>
  <div>
    <br>
    <v-row>
      <v-card-text>
      <h3>Venho requerer do Serpros - Fundo Multipatrocinado, nos termos dos regulamentos básicos e complementar ao qual aderi:</h3>
      </v-card-text>
    </v-row>

    <v-row>
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-select
          label="Escolha uma opção"
          :value="value.autopatrocinio"
          @input="setAutopatrocinio"
          :items="lista_opcoes"
          item-text="descricao"
          item-value="id"
          :rules="Rules.Required" 
          :readonly="situacao != 'L'"
        > 
        </v-select>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-select
          label="Em razão de"
          :value="value.razao"
          @input="setRazao"
          :items="filtraOpcoes2()"
          item-text="descricao"
          item-value="id"
          :rules="Rules.Required" 
          :readonly="situacao != 'L'"
        > 
        </v-select>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-text-field  
            type="date" 
            :value="value.data_ini" 
            @input="setDataIni" 
            label="Data inicial"  
            :rules="[...Rules.Required]" 
            :readonly="situacao != 'L'"
            v-if="razao != 'D'"
        >
        </v-text-field>
      </v-col> 
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-text-field  
            v-if="razao == 'D'"
            type="date" 
            :value="value.data_fim" 
            @input="setDataFim" 
            label="Data Final"  
            :readonly="situacao != 'L'"
            :rules="[...Rules.Required]" 
        >
        </v-text-field>
        <v-text-field  
            v-if="razao != 'D'"
            type="date" 
            :value="value.data_fim" 
            @input="setDataFim" 
            label="Data Final"  
            :readonly="situacao != 'L'"
            
        >
        </v-text-field>
      </v-col> 

    </v-row>  
    <v-row>
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-select
          label="Deseja alterar a contribuição variável?"
          :value="value.alterar_variavel"
          @input="setAlterarVariavel"
          :items="sim_nao"
          item-text="descricao"
          item-value="id"
          :rules="Rules.Required" 
          :readonly="situacao != 'L'"
        > 
        </v-select>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-text-field
          type='number'
          label="Percentual de contribuição variável" 
          :value="value.percentual_variavel"
          @input="setPercentualVariavel"
          :readonly="situacao != 'L' || value.alterar_variavel != 'S'"
          :rules="[...regra_variavel(value.percentual_variavel)]"
          hide-spin-buttons
          prepend-icon="mdi-help-circle"
        >
          <template v-slot:prepend>
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  mdi-help-circle
                </v-icon>
              </template>
              A contribuição variável é calculada pela aplicação de um percentual<br> 
              sobre a parcela do Salário de Contribuição excedente a 8 (oito) VRS.<br> 
              Ex.: Para um percentual de 15% e um salário de
              {{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(salario) }} o cálculo será:<br> 
              ({{Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(salario)}} - 
              {{Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(8*vrs)}}) * 15% = 
              {{(salario - 8*vrs)*0.15 > 0 ? Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format((salario - 8*vrs)*0.15) : "R$ 0,00"}}
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0"> 
        <v-select
          label="Deseja alterar a contribuição espontânea?"
          :value="value.alterar_espontanea"
          @input="setAlterarEspontanea"
          :items="sim_nao"
          item-text="descricao"
          item-value="id"
          :rules="Rules.Required" 
          :readonly="situacao != 'L'"
          
        > 
        </v-select>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-text-field
          type='number'
          label="Valor da contribuição mensal espontânea" 
          :value="value.valor_espontanea"
          @input="setValorEspontanea"
          :readonly="situacao != 'L' || value.alterar_espontanea != 'S'"
          :rules="[...regra_espontanea(value.valor_espontanea)]"
          hide-spin-buttons
          prepend-icon="mdi-help-circle"
        >
          <template v-slot:prepend>
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  mdi-help-circle
                </v-icon>
              </template>
               Pode ser definido um valor em R$ a ser pago mensalmente. <br>
               O valor definido para a contribuição espontânea é limitado a<br>
               15% do seu Salário de Contribuição. <br>
               
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </div>  
</template>    

<script>
  
    export default {  
        name: "ReqAutopatrocinio",
        props: ['value','id_plano','situacao','salario','vrs'], 
        data: function(){
          return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            autopatrocinio: this.value.autopatrocinio,
            razao: this.value.razao,
            data_ini: this.value.data_ini,
            alerar_variavel: this.value.alterar_responsavel,
            percentual_variavel: this.value.percentual_variavel,
            alerar_espontanea: this.value.alterar_espontanea,
            valor_espontanea: this.value.valor_espontanea,
            lista_opcoes: [
              {id:'P', descricao:'Autopatrocínio Parcial'},
              {id:'I', descricao:'Autopatrocínio Integral'}
            ],
            lista_opcoes_2: [
              {id:'D', descricao:'Desligamento da Patrocinadora'},
              {id:'A', descricao:'Licença da Patrocinadora'},
              {id:'P', descricao:'Perda Parcial de Remuneração'},
            ],
            sim_nao: [
              {id:'S', descricao:'SIM'},
              {id:'N', descricao:'NÃO'},

            ],
            lista_percentual: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],
            //exemplo: "Ex.: Para um percentual de 15% e um salário de "+ salario +" o valor da contribuição será ",
          }
        },  
        methods: {

          regra_variavel: function(percentual) {

            var resultado = true;
            var mensagem = '';

            if (percentual > 15){
      
                resultado = false;
                mensagem = 'Máximo 15%';
              
            }

            return  resultado || mensagem
          },

          regra_espontanea: function(valor) {

            var resultado = true;
            var mensagem = '';

            let maximo = this.salario * 0.15;
            maximo = parseFloat(maximo.toFixed(2));

            if (valor > maximo){
      
                resultado = false;
                mensagem = 'Máximo '+maximo;
              
            }

            return  resultado || mensagem
          },
          
          filtraOpcoes2: function(){
          
            var autopatrocinio = this.autopatrocinio;

            return this.lista_opcoes_2.filter(function(opcao) {
              var exibe = false;
              if(autopatrocinio == 'P'){
                if(opcao.id == 'P'){
                  exibe = true;
                }

              }else{
                if(opcao.id != 'P'){
                  exibe = true;
                }
              }

              return exibe;
            })
          },

          updateValue() {
           //console.log(this.beneficio);  
            this.$emit('input', {
              autopatrocinio: this.autopatrocinio,
              razao: this.razao,
              data_ini: this.data_ini,
              data_fim: this.data_fim,
              alterar_variavel: this.alterar_variavel,
              percentual_variavel: this.percentual_variavel,
              alterar_espontanea: this.alterar_espontanea,
              valor_espontanea: this.valor_espontanea

            });
          },

          setAutopatrocinio(opcao){
           this.autopatrocinio = opcao;
           this.updateValue();
          },

          setRazao(razao){
           this.razao = razao;
           this.updateValue();
          },

          setDataIni(data_ini){
           this.data_ini = data_ini;
           this.updateValue();
          },

          setDataFim(data_fim){
           this.data_fim = data_fim;
           this.updateValue();
          },

          setAlterarVariavel(alterar_variavel){
           this.alterar_variavel = alterar_variavel;
           this.updateValue();
          },

          setPercentualVariavel(percentual_variavel){
           this.percentual_variavel = percentual_variavel;
           this.updateValue();
          },

          setAlterarEspontanea(alterar_espontanea){
           this.alterar_espontanea = alterar_espontanea;
           this.updateValue();
          },

          setValorEspontanea(valor_espontanea){
           this.valor_espontanea = valor_espontanea;
           this.updateValue();
          },

        }
    }
</script>