<template>
  <div>
    <br>
    <v-row>
      <v-col cols="12" md="4" class="my-0 py-0">
        <v-text-field
          :value = "value.nome_entidade"
          @input = "setNome"
          label="Nome da Entidade Receptora"
          :rules="Rules.Required" 
          :readonly="situacao != 'L'" 
          autocomplete="none"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="4" class="my-0 py-0">
        <v-text-field
          :value = "value.nome_plano"
          @input = "setPlano"
          label="Nome do Plano de Benefícios"
          :rules="Rules.Required" 
          :readonly="situacao != 'L'"
          autocomplete="none"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="4" class="my-0 py-0">
        <v-text-field
          :value = "value.cnpb"
          @input = "setCnpb"
          label="Código Nacional do Plano de Benefícios - CNPB"
          :rules="Rules.Required" 
          :readonly="situacao != 'L'"
          autocomplete="none"
        >
        </v-text-field>
      </v-col>
     
    </v-row>

  </div>  
</template>    

<script>
  
    export default {  
        name: "ReqPortabilidadeSaida",
        props: ['value','id_plano','situacao'], 
        data: function(){
          return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            nome_entidade: this.value.nome_entidade,
            nome_plano: this.value.nome_plano,
            cnpb: this.value.cnpb,
          }
        },  
        methods: {

          updateValue() {
           //console.log(this.beneficio);  
            this.$emit('input', {
              nome_entidade: this.nome_entidade,
              nome_plano: this.nome_plano,
              cnpb: this.cnpb,
            });
          },
          setNome(nome){
           this.nome_entidade = nome;
           this.updateValue();
          },
          setPlano(plano){
           this.nome_plano = plano;
           this.updateValue();
          },
          setCnpb(cnpb){
           this.cnpb = cnpb;
           this.updateValue();
          },
          
        }
    }
</script>