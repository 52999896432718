import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#1565C0',
            secondary: '#00aeef',
            tertiary: '#8b9412',
            anchor: '#8c9eff',
            success: '#d6df21',
            table: 'eaeff7'
          },
        },
    },
});
