<template>
  <div>
    <v-card >

      <v-card-text> 

        <v-row>
          <v-col cols="12" md="11" class="text-left">
              <h2 v-if="tipo==1"> Declaração de Beneficiários necessária ao dimensionamento dos encargos futuros previstos com pensão por morte </h2> 
          </v-col>
          
        </v-row>
      </v-card-text> 
      <v-card-text class="text-justify text-h6 font-weight-light"> 
        <v-row>
          <v-col v-if="tipo==1" cols="12" md="12" class="text-justify text-h6 font-weight-light">
            O requerente ao Beneficío de aposentadoria, nos termos do regulamento do Plano de Beneficíos SERPRO II(PS-II), declara que a relação abaixo compreende seu(s) beneficiário(s) nomeado(s), estando ciente que esta declaração destina-se ao cálculo da aposentadoria, de acordo com a base técnica do PS-II vigente no momento da conversão do saldo da conta do Participante em renda e ao dimensionamento dos encargos futuros previstos com pensão por morte. Declara, ainda, que esta informação cancela e substitui toda e qualquer outra anteriormente prestada ao SERPROS sobre o assunto, sendo que ocorrendo a nomeação de novo(s) beneficiário(s) não declarado(s) neste termo, enseja a redução do valor da prestação mensal de aposentadoria na proporção necessária para evitar que a inscrição de beneficiário(s) produza elevação dos encargos do plano. <b>O requerente declara, ainda, estar ciente que somente terão direito a pensão por morte os beneficiários indicados por ele nessa declaração.</b>
          </v-col>
          <v-col v-if="tipo==13" cols="12" md="12" class="text-justify text-h6 font-weight-light">
            Informe abaixo os seus dependentes para fins de cálculo do Imposto de Renda Retido na Fonte.
          </v-col>
        </v-row>
      </v-card-text> 
      <v-card-text class="text-justify text-h6 font-weight-light"> 
          <b>Selecione abaixo seus beneficiários nomeados. Caso você deseje cadastrar novos beneficiários utilize a opção Cadastro/Dados pessoais na 
                <a class="primary--text" href="#" @click="areaRestrita();">Área do Participante</a>
                e depois retorne ao requerimento para finalizar essa declaração.
          </b>
      </v-card-text> 

      <v-card-text>  
        <v-data-table
          :headers="header"
          :items="pensionistas_trust"
          sort-by="nm_pessoa"
          class="elevation-2 table"
          no-data-text="Não há nenhum bebeficiário"
          :loading="carregando"
          loading-text="Carregando Beneficiários ..."
          :items-per-page="-1"
          hide-default-footer
          >
          
          <template v-slot:item.dt_nascimento="{ item }">
            <span>{{new Date(item.dt_nascimento).toLocaleString("pt-BR",{dateStyle: "short"})}}</span>
          </template>
          <template v-slot:item.ic_sexo="{ item }">
            <span>{{item.ic_sexo =='M' ? 'Masculino' : 'Feminino' }}</span>
          </template>
          <template v-slot:item.selecionado="{ item }">
            <v-checkbox
              v-model="item.selecionado"
              @click="updateValue()"
            ></v-checkbox>
          </template>

        </v-data-table>
      </v-card-text>
    </v-card>

  </div>  
</template>    

<script>
  
    export default {  
        name: "ReqDependentesBenef",
        props: ['value','situacao','docs_obrigatorios', 'tipo', 'pensionistas_trust', 'carregando'], 
        data: function(){
          return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            dependentes: this.value,
            sexo:[
              {id:'F', descricao:'Feminino'},
              {id:'M', descricao:'Masculino'},
            ],
            dependencia: [
              {id:1, descricao:'Cônjuge ou ex-cônjuge pensionista'},
              {id:2, descricao:'Companheiro(a) em união estável'},
              {id:3, descricao:'Ex-companheiro(a) pensionista'},
              {id:4, descricao:'Filhos, enteados e tutelados solteiros e menores de 21 anos'},
              {id:5, descricao:'Filhos, enteados e tutelados solteiros e inválidos'},
              {id:6, descricao:'Pais dependentes econômicos'},
            ],
            header: [
              
                {
                    text: 'Nome',
                    align: 'start',
                    sortable: false,
                    value: 'nm_pessoa',
                    width: 180,
                    class: 'title white--text'
                },
                {
                    text: 'Data de Nascimento',
                    value: 'dt_nascimento',
                    width: 100,
                    class: 'title white--text'
                },
                {
                    text: 'Sexo',
                    value: 'ic_sexo',
                    width: 100,
                    class: 'title white--text'
                },
                {
                    text: 'Parentesco',
                    value: 'nm_parentesco',
                    width: 100,
                    class: 'title white--text'
                },
                {
                    text: 'Selecionado',
                    value: 'selecionado',
                    sortable: false,
                    width: 120,
                    class: 'title white--text'
                },
            ],
          }
        },  
        methods: {

          updateValue() {
           
            this.dependentes = [];

            let len = this.pensionistas_trust.length;
            for(let i=0; i<len; i++){
              if(this.pensionistas_trust[i].selecionado){
                this.dependentes.push({ id: null,
                                      nome: this.pensionistas_trust[i].nm_pessoa,
                                      cpf: this.pensionistas_trust[i].nr_cnpj_cpf,
                                      data_nascimento: this.pensionistas_trust[i].dt_nascimento,
                                      sexo:  this.pensionistas_trust[i].ic_sexo,
                                      dependencia: this.pensionistas_trust[i].id_parentesco, 
                                      docs_obrigatorios: this.docs_obrigatorios,
                                      id_pessoa_dep: parseInt(this.pensionistas_trust[i].id_pessoa_dep),                  
                });
              }
              
            }

            this.$emit('input', this.dependentes);
          },


          areaRestrita(dependentes){

              //this.loading = true;

              this.$store.state.API.post( '/api/requerimento/arearestrita',{})
              .then(response => {

                window.open( 'http://10.0.80.14:8109/login/'+response.data.codigo,'_blank');
                //this.loading = false;
        
              });

          
          },
         

          

        }
    }
</script>