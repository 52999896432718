<template>
   <v-container 
         fluid
   >
      <v-row v-if="!mostra_login && loading" class="mt-5">
        <v-col>
          <div class="text-center" >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </v-col>
      </v-row>  
      <v-row v-if="loading" >
        <v-col>
          <div class="text-center" >Carregando</div>
        </v-col>
      </v-row> 

      <v-row
          justify="center"
          v-if="mostra_login"
        >
         <v-col
            sm="8"
            md="4"
          >
            <v-card
               elevation="8"
            >
               <v-toolbar
                color="primary"
                dark
                flat
                dense
              >
                <v-toolbar-title>Requerimentos 3.1</v-toolbar-title>

                 <v-progress-linear
                  :active="loading"
                  :indeterminate="loading"
                  absolute
                  bottom
                  color="secondary"
                  ></v-progress-linear>
               
                </v-toolbar>

               <v-card-text>
                  <v-form 
                     @submit.prevent="Entrar" 
                     ref="form"
                     v-model="valid"
                     lazy-validation
                  >
                     <v-img
                        alt="Serpros Fundo Multipatrocinado"
                        class="shrink"
                        contain
                        src="img/serpros-logo.png"
                        height="60"
                     />

                     <br>

                     <v-text-field
                        v-model="login.cpf"
                        label="CPF"
                        :rules="Rules.Required"
                     ></v-text-field>

                     <v-text-field
                        v-model="login.senha"
                        label="Senha da área restrita"
                        type="password"
                        :rules="Rules.Required"
                     ></v-text-field>
                     
                     <div class="text-center">
                        <v-btn type="submit"  color="primary" :disabled="!valid"> Entrar </v-btn>
                     </div>
                     <br>
                     <v-alert v-if="mostra_mensagem" v-model="mostra_mensagem"  text type="error"> {{mensagem}} </v-alert>
                  </v-form>

                  <div class="text-center">
                    <v-btn plain color="primary"  @click="NaoTenhoSenha"> Não tem senha? clique aqui! </v-btn>
                  </div>
               </v-card-text>   
            </v-card>
         </v-col>
      </v-row>
   </v-container>

   
</template>

<script>
  export default {
    data: function(){
      return {  
          active: null,
          indeterminate: null,
          loading: true,
          valid: false,
          Rules:{
            Required:[v => !!v || 'Obrigatório!'],   
          },
          login:{
            cpf:null, 
            senha:null
          },
          mensagem: '',
          mostra_mensagem: false,
          mostra_login: false,
          token: null,
      }
    },

    created: function () {
      
      var texto ='<p style="color:white; font-size: 12px;">Preecha o CPF e a senha da área restrita.</p>'+
                 '<p style="color:white; font-size: 12px;">Versão 3.1.</p>';
      this.$store.dispatch('exibeajuda',texto);
      this.token = this.$route.params.token;
      this.Carregar();
    },
    methods:{


      Entrar() {
        if( this.$refs.form.validate() ){
            this.mostra_mensagem = false;
            this.loading = true;

            
            var params = {
              cpf: this.login.cpf.replace(/[^\d]+/g,''),
              senha: this.login.senha
            }
          

            this.$store.state.API.post( '/api/autenticar', params)
            .then(response => {
              
              this.token = response.data; 
              this.$store.state.API.Iniciar(this.token);
              this.token = null;
              this.iniciarSessao();                   
            })
            .catch(error => {
              console.log(error.response);
              this.mensagem = 'Usuário ou senha inválidos';
              this.loading = false;
              this.mostra_mensagem = true;
              
            });  
        }
      },




      Carregar(){

      if(this.$route.query.code != null){

          
          this.mostra_login = false;

          let uri2 = process.env.VUE_APP_API + '/api/singlesignon';
          let params2 = {
            codigo: this.$route.query.code,
          };
          
          //PEDE UM TOKEN DE ACESSO COM O CÓDIGO RECEBIDO
          return this.axios.post(uri2, params2)
          .then(response => {

           // console.log(response.data.original.access_token);

            this.token = response.data.original; 
            this.$store.state.API.Iniciar(this.token);
            this.token = null;
            this.iniciarSessao();    

            //this.loading = false;
  
          })
          .catch(error => {
            console.log( error.response.data.msg);
            this.loading = false;
          });

      }else{
         this.loading = false;
         this.mostra_login = true;
      }
 
      },


      iniciarSessao(){

        this.$store.state.API.get( '/api/iniciarsessao')
        .then(response => {
            if(response.data.codigo == 'ok'){
              //console.log(response.data);
              
              this.$store.state.API.setTokenGed(response.data.token_ged);

              var usuario = {
                logado: true,
                nome: '',
                id: '',
                email: '',
                matricula: '',
                id_setor: ''
              };

              this.$store.commit('setusuariologado', usuario);
              this.$store.commit('setcpf',response.data.cpf.replace(/[^\d]+/g,''));
              this.$store.commit('setnome',response.data.nome);
              this.$store.commit('setid_pessoa_fisica',response.data.id_pessoa_fisica);
              response.data.endereco.id = null;
              response.data.endereco.id_pais = parseInt(response.data.endereco.id_pais);
              this.$store.commit('setendereco',response.data.endereco);

              this.$store.commit('setdataNascimento',response.data.data_nascimento);
              this.$store.commit('setPermissaoResgate',response.data.permissao_resgate);
              //this.$store.commit('setdtIniPlano',response.data.dt_ini_plano);
            
              if(!response.data.plano_participante){
                  this.$store.commit('setPlanoParticipante', []);
              }else{
                  
                  for (let i = 0; i < response.data.plano_participante.length; i++) {

                    if(response.data.plano_participante[i].id_plano == 2){
                       response.data.plano_participante[i].percentual_variavel = parseFloat(response.data.plano_participante[i].percentual_variavel);

                    }
                    
                  }
                  
                  this.$store.commit('setPlanoParticipante', response.data.plano_participante);
                
                  
              }
              
              

              this.$store.dispatch('iniciarRelogio');

            
              this.carregaTabelas();

            }else{
              console.log(response.data);
              this.loading = false;
              this.mostra_login = true;
            }
            
        })
        .catch(error => {
            console.log(error.response);
            this.loading = false;
            this.mostra_login = true;
        });
      },
      carregaTabelas(){
        //console.log(this.token);
    /*    this.axios({
            method: 'get',
            url: this.$store.state.urls.apipartic+'/api/tabelas',
            headers: { 'Authorization': 'Bearer '+ this.$store.state.token_partic },
            withCredentials: true
        })  */

        this.$store.state.API.get('/api/tabelas')
        .then(response => {
            if(response.data.codigo == 'ok'){

              //console.log(response.data.docsobrigatorios);
              
              this.$store.commit('setplanos',response.data.planos);
              this.$store.commit('setbeneficios',response.data.beneficios);
              this.$store.commit('setsituacoes',response.data.situacoes);
              this.$store.commit('settipos_documento',response.data.tiposdocumento);
              this.$store.commit('setdocs_obrigatorios',response.data.docsobrigatorios);
              this.$store.commit('settipos_req',response.data.tipos_req);
              this.$store.commit('setmaxfilesize',response.data.max_file_size);
              this.$router.push('/');
            }else{
              this.loading = false;
              this.mostra_login = true;
            }
            
        })
        .catch(error => {
            console.log(error.response.data);
            this.loading = false;
            this.mostra_login = true;
        });
      },
      
      NaoTenhoSenha(){
        this.$router.push('/naotemsenha');
      }  



    }
    
     
  }
</script>