<template>
  <div>

  <v-snackbar
    v-model="exibe_alerta"
    timeout="2000"
    color="green"
  >
    {{ alerta }}
  </v-snackbar> 

  <v-container fluid>


    <v-card  elevation="4" >
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field v-model="nome_participante" dense hide-details outlined label="Nome" readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="cpf_participante" dense hide-details outlined label="CPF" readonly></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>   


      <br>
      <v-form ref="form" v-model="form_valido" lazy-validation>  
        <v-row v-if="loading" class="mt-5">
          <v-col>
            <div class="text-center" >
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </v-col>
        </v-row>  
        <v-row v-if="loading" >
          <v-col>
            <div class="text-center" >Carregando</div>
          </v-col>
        </v-row> 

        <v-card outlined elevation="4" v-if="!loading">
          <v-card-title>
              <v-row>
                  <v-col class="text-left">
                      <h4 class="text--secondary"> Documentos</h4>
                  </v-col>
              </v-row>
          </v-card-title>
          
          <v-card-text>
                  <v-data-table
                    :headers="headerDocumentos"
                    :items="documentos"
                    sort-by="data_envio"
                    sort-desc
                    class="elevation-2 table"
                    no-data-text="Não há nenhum arquivo"
                    :items-per-page="10"
                    :loading="loading.anexo"   
                    loading-text="Carregando arquivos..."
                    :footer-props="{
                      itemsPerPageText: 'Linhas por página',
                      itemsPerPageAllText: 'Todas',
                    }"
                    
                    >
                    
                    <template v-slot:item.data_gravacao="{ item }">
                      <span>{{new Date(item.data_gravacao).toLocaleString("pt-BR",{dateStyle: "short"})}}</span>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                      <v-btn icon class="mr-2 text-center" @click="downloadAnexo(item)">
                          <v-icon>mdi-download-circle</v-icon>
                      </v-btn>
                    </template>

                    <template v-slot:[`item.escolher`]="{ item }">
                      <v-btn small class="mr-2 text-center" @click="AnexaArquivo(item)">Anexar</v-btn>
                    </template>

                  </v-data-table>
          </v-card-text>  
        </v-card>

        <br>

        <v-row>
          <v-col class="text-right">
            <v-btn rounded   color="#B0BEC5"   :to="'/requerimento/'+this.$route.params.id_requerimento" v-if="!loading"> <v-icon>mdi-arrow-left-circle</v-icon> Sair</v-btn> 
          </v-col>
        </v-row>  

        <v-overlay
          absolute
          :value="dialog"
        >
        </v-overlay>

        <v-snackbar
          v-model="dialog"
          multi-line
          centered
          :color="cor_dialogo"
          timeout="-1"
          max-width="400"
          min-height="140"
          vertical
        >
          {{ mensagem }}

          <template v-slot:action="{ attrs }">
                    <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="BtnOk()"
              v-if="!sim_nao"
            >
              Ok
            </v-btn>
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="BtnSim()"
              v-if="sim_nao"
            >
              Sim
            </v-btn>
                    <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="BtnNao()"
              v-if="sim_nao"
            >
              Não
            </v-btn>
          </template>
        </v-snackbar>

      </v-form>  
  </v-container>
  </div>
</template>

<script>
   export default {

      data: function(){
         return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            multiLine: true,
            absolute: false,
            form_valido: true,
            loading: true,
            mensagem: '',
            dialog: false,
            titulo: null,
            cor_dialogo: '#455A64',
            sim_nao: false,
            reverse: false,
            resposta: false,
            autorizado: false,
            nome_participante: "",
            cpf_participante: "",
            documentos: [],
            respostas:[],
            exibe_alerta: false,
            alerta: '',
            headerDocumentos: [
              
                {
                    text: 'Documentos',
                    align: 'start',
                    sortable: false,
                    value: 'tipo_documento',
                    width: 160,
                    class: 'title white--text'
                },
                {
                    text: 'Nome Arquivo',
                    value: 'arquivo',
                    width: 160,
                    class: 'title white--text'
                },
                {
                    text: 'Data Gravação',
                    value: 'data_gravacao',
                    width: 80,
                    class: 'title white--text'
                },
                {
                    text: 'Download',
                    value: 'actions',
                    sortable: false,
                    width: 20,
                    class: 'title white--text'
                },
                {
                    text: 'Escolher',
                    value: 'escolher',
                    align: 'end',
                    sortable: false,
                    width: 20,
                    class: 'title white--text'
                },
            ],
            
         }
      },
      created: function() {
        var texto = '<p style="color:white; font-size: 12px;">Nesta tela você poderá visualizar todos os documentos de um determinado tipo que já estão na sua nuvem.</p>'+
                    '<p style="color:white;font-size: 12px;">- Clique no botão <b>Anexar</b> para anexar o documento ao seu requerimento. </p>'+
                    '<p style="color:white;font-size: 12px;">- Você pode clicar no ícone de Download para baixar o documento e conferir seu conteúdo se desejar.</p>';

        this.$store.dispatch('exibeajuda',texto);

        this.nome_participante = this.$store.state.nome;
        this.cpf_participante = this.$store.state.cpf;

        if(this.$store.state.alerta != ''){
           this.alerta = this.$store.state.alerta;
           this.$store.commit('setalerta','');
           this.exibe_alerta = true;
        }

        this.recuperaDocumentos();
        
      },
      methods: {
        //#####################
        //Regras de validação do formulário
        //####################
        
        //#####################
        //click dos botões da tela
        //####################

        BtnOk(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 

        BtnSim(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 


        BtnNao(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          
        }, 


        sair(){

          var usuario = {
                logado: false,
                nome: '',
                id: '',
                email: '',
                matricula: '',
                id_setor: ''
              };

          this.$store.commit('setusuariologado', usuario);

          this.$router.push('/login');
         

        }, 
        

        recuperaDocumentos(){

          this.loading = true;

          this.$store.state.API.get(  '/api/nuvem/'+this.$store.state.nuvem.id )
          .then(response => {
            if(response.data.codigo == 'ok'){
              this.documentos = response.data.documentos;
              this.loading = false;

              
            }
          })
          .catch(error => {
              this.TrataErros(error);
          });

        },



        downloadAnexo(item)
        {  
          this.loading = true;
          this.$store.state.API.getFile('/api/documento/' + item.id_documento_ged +'/download')
          .then(response => {
              //console.log(response.data);

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', item.arquivo);
              document.body.appendChild(link);
              link.click();
              this.loading = false;
              
          })
          .catch(error => {
          
              this.TrataErros(error);
          });  
        },


        AnexaArquivo(item)
        {  
          this.loading = true;

          let data = {
              id_requerimento: this.$route.params.id_requerimento,
              id_documento: item.id,
              id_tipo_documento: item.id_tipo_documento,
              usuario: this.$store.state.cpf,
              id_nuvem: this.$store.state.nuvem.id,
            };

          this.$store.state.API.post( '/api/requerimento/complementar/nuvem', data )
          .then(response => {
            if(response.data.codigo == 'ok'){
              this.$store.commit('setalerta','Documento anexado com sucesso!');
              this.$router.push('/requerimento/'+this.$route.params.id_requerimento);
            }
          })
          .catch(error => {
              this.TrataErros(error);
          });
        },
        //#####################
        //funções utilitárias
        //####################


        TrataErros(error){

          console.log(error.response);

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.sim_nao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if('data' in error.response){
            if('mensagem' in error.response.data){
              if( error.response.data.mensagem == 'Token expirado'){
                token_expirado = true;
              }  
            }

            if(token_expirado){
              this.mensagem = 'Sua sessão expirou. Faça um novo login.';
            } 
          }

          this.dialog = true;

          var usuario = {
              logado: false,
              nome: '',
              id: '',
              email: '',
              matricula: '',
              id_setor: ''
            };

            this.$store.commit('setusuariologado', usuario);
        }
      },
   }
</script>