<template>
  <v-text-field
    :label="titulo"
    :value="formatedValue"
    @input="handleInput"
    @keypress="filter"
    :maxlength="10"
    :outlined="outlined" 
    :rounded="rounded"
    :dense="dense"
    :rules="rules"
    :readonly="readonly"
  >
  </v-text-field>
</template>


<script>

//import formatNumber from 'accounting-js/lib/formatNumber';
//import unformat from 'accounting-js/lib/unformat';

export default {
  name: "CompData",
  //props: ['value','titulo','outlined','rounded'],
  props: {
    value: {
      type: String,
      default: "",
      required: true,
    },
    titulo: {
      type: String,
      default: "",
      required: false,
    },
    outlined: {
      type: Boolean,
      default: false,
      required: false,
    },
    rounded: {
      type: Boolean,
      default: false,
      required: false,
    },
    dense: {
      type: Boolean,
      default: false,
      required: false,
    },
    rules: {
      type: Array,
      required: false,
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },

  },
  data() {
    return {
      valor: this.value,
      formatedValue: this.formataPTBR(this.value),
    };
  },


   watch: {
    value() {
      this.formatedValue = this.formataPTBR(this.value);
    }
  }, 


  methods: {
    handleInput(e) {
      this.valor =this.formataYMD(e);
      this.formatedValue = this.formataPTBR(this.valor);
      //console.log(teste);
      this.$emit("input", this.valor);
    },
    formataPTBR(value) {
      if(value == ""){
        return "";
      }else{

        let novo_texto = value.replaceAll("-","");
        novo_texto = novo_texto.replaceAll(" ","");

        let len = novo_texto.length;
        let ano = "";
        let mes = "";
        let dia = "";
        let resultado = "";
        
        if(len >= 1){
          dia = novo_texto.substr(len-2, 2);
          resultado = dia;
        }

        if(len >= 2){
          resultado = resultado+"/";
        }

        if(len >= 3){
          if(len == 3){
            mes = novo_texto.substr(0,1);
          }else{
            let start = len-4;
            mes = novo_texto.substr(start,2);
          }
          resultado = resultado+mes;
        }

                  
          

        if(len >= 4){
          ano = novo_texto.substr(0, len-4);
          resultado = resultado+"/"+ano;
        }
        
        

        return resultado;

      }

     
        
      
    },
    formataYMD(value) {
        let texto = value;
        let novo_texto = texto.replaceAll("/","");
        novo_texto = novo_texto.replaceAll(" ","");

        let len = novo_texto.length;
        let ano = "";
        let mes = "";
        let dia = "";

        if(len > 4){
          ano = novo_texto.substr(4,4);
        }else{
          ano = ""; 
        }

        if(len > 2){
          mes = novo_texto.substr(2,2);
        }else{
          mes = ""; 
        }

        if(len > 0){
          dia = novo_texto.substr(0,2);
        }else{
          dia = ""; 
        }

        if(len == 0){
          return "";
        }else{
          return ano+"-"+mes+"-"+dia;
        }
    },

    filter: function(evt) {
      evt = (evt) ? evt : window.event;
      let expect = evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },


  }


};
</script>